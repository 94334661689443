import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, Button, Form, Col } from 'react-bootstrap';
import { COLORS } from '../styles/theme';
import Axios from 'axios';
import config from '../config';
import { toast } from 'react-toastify';

const Wrapper = styled.div`
	#btn {
		background: none;
		border: none;
		color: ${COLORS.primary};
		font-size: 0.9em;
		margin-top: 10px;
	}
`;
const CustomModal = styled(Modal)`
  .modal-content {
    border: none;
    border-radius: 28px;
    padding: 16px;
    .modal-title {
      text-align: center;
    }
    .modal-body {
      padding-bottom: 0;
    }
    label {
      padding: 4px 16px;
    }
    input {
      border-radius: 18px;
      background: ${COLORS.lightGray};
      padding: 4px 16px;
      &::placeholder {
        font-size: 14px;
      }
    }
    button#verify-btn {
      display: block;
      margin-right: 0;
      margin-left: auto;
      border: 1px solid #fff;
      font-weight: 500;
    }
  }
  .modal-header {
    border: none;
    padding: 0 16px;
  }
`;

export default function ForgetPassword({ children }) {
	const [ show, setShow ] = useState(false);
	const [ email, setEmail ] = useState('');
	const [ loading, setLoading ] = useState(false);

	const handleClose = () => setShow(false);

	const handleClick = (e) => {
		e.preventDefault();
		setShow(true);
	};

	const handleInput = (e) => setEmail(e.target.value.toLowerCase());

	const handleSubmit = (e) => {
		setLoading(true);
		e.preventDefault();
		Axios.get(`${config.apiUrl}/api/v1/forget_password`, {
			params: {
				email
			}
		})
			.then(function(response) {
				setLoading(false);
				if (response.data.exist) {
					toast('We have sent you the password via email, please check your mailbox.');
					setEmail('');
					setShow(false);
				} else {
					toast('This email does not seem to be registered to our system.');
				}
			})
			.catch(function(error) {
				setLoading(false);
				toast(error.response.data.message);
			});
	};

	return (
		<Wrapper>
			<div id="btn" onClick={handleClick}>
				{children}
			</div>
			<CustomModal show={show} onHide={handleClose} size="md" aria-labelledby="schedule-modal" centered>
				<Modal.Header closeButton>
					<Modal.Title>Forgot Password</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Row>
						<Form.Group as={Col} controlId="formGridEmail">
							<Form.Label>Enter Email to request for Password</Form.Label>
							<Form.Control
								type="email"
								placeholder="Enter email here..."
								onChange={handleInput}
								value={email}
							/>
						</Form.Group>
					</Form.Row>
					<Button
						id="verify-btn"
						disabled={email.length < 4 || loading}
						variant="default"
						style={{ background: '#002060', borderRadius: '12px', color: '#fff' }}
						onClick={handleSubmit}
					>
						{loading ? 'Verifying...' : 'Verify Email'}
					</Button>
				</Modal.Body>
			</CustomModal>
		</Wrapper>
	);
}
