import React from 'react';
import { Modal } from 'react-bootstrap';
import guide from '../../images/backdrops/guide.png';
import { COLORS } from '../../styles/theme';
import styled from 'styled-components';

const StyledModal = styled(Modal)`
  .modal-content {
    border: 2px solid ${COLORS.primaryLight};
    box-shadow: 0px 4px 12px ${COLORS.lightGray1} ;
  }
  .modal-header{
    border: none;
  }
  .modal-body {
    padding: 0;
  }
`;

export default function GuideModal({ showModal, hideSchedule }) {
	return (
		<StyledModal show={showModal} onHide={hideSchedule} size="xl" aria-labelledby="user-guide" centered>
			<Modal.Header closeButton />
			<Modal.Body>
				<img src={guide} width="100%" alt="user-guide" />
			</Modal.Body>
		</StyledModal>
	);
}
