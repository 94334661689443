import { lighten, darken, transparentize, adjustHue } from 'polished';

const _PRIMARY = '#002F62';
const _NAV = '#F8F4FF';
const _ACCENT = 'rgb(185, 158, 83)';
const _NOTICEBAR = '#000000';

const COLORS = {
	nav: _NAV,
	noticeBar: _NOTICEBAR,

	primary: _PRIMARY,
	primaryLight: lighten(0.2, _PRIMARY),
	primaryDark: darken(0.2, _PRIMARY),
	primaryComp: adjustHue(40, _PRIMARY),
	primaryTrans: transparentize(0.2, _PRIMARY),

	accent: _ACCENT,
	accentLight: lighten(0.2, _ACCENT),
	accentDark: darken(0.2, _ACCENT),
	accentComp: adjustHue(40, _ACCENT),
	accentTrans: transparentize(0.2, _ACCENT),

	blue: '#031E88',
	purple: '#3A2D86',

	lightGray: '#F2F2F2',
	lightGray1: '#B1B1B1',

	text: '#404040',

	pink: '#d1586a',
	darkPink: '#F5216A',

	cyan: '#26B4A9'
};

const FONTS = {
	primary: "'Montserrat', sans-serif;",
	secondary: "'Montserrat', sans-serif;"
};

/**
 * NAVBAR, FLOATING NAV, MOBILE THRESHOLD
 */

// const _MOBILE_BP = '700px'
const _MOBILE_BP = '1024px';
const _NOTICEBAR_HEIGHT = '30px';

const _FLOATNAV_HEIGHT = {
	landscape: '60px',
	portrait: '40px'
};

const _FLOATNAV_BOTTOM = {
	landscape: '63px',
	portrait: '10px'
};

const SIZES = {
	navHeight: _NOTICEBAR_HEIGHT,
	mobileBreakpoint: _MOBILE_BP,

	mobile: `@media (max-width: ${_MOBILE_BP})`,

	noticeBar: `height: ${_NOTICEBAR_HEIGHT};`,

	floatNav: `
    height: ${_FLOATNAV_HEIGHT.landscape};
    bottom: ${_FLOATNAV_BOTTOM.landscape};

    @media (max-width: ${_MOBILE_BP}) {
      height: ${_FLOATNAV_HEIGHT.portrait};
      bottom: ${_FLOATNAV_BOTTOM.portrait};
      padding: 5px 16px 5px 16px;
    }
  `,

	rightBelowNoticeBar: (offset) => `
      top: calc(${_NOTICEBAR_HEIGHT} + ${offset}px);
    `
};

export { COLORS, FONTS, SIZES };
