import React, { useEffect, useState } from 'react';
import { useList } from 'react-firebase-hooks/database';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import GoogleSheetForm from '../components/GoogleSheetForm';
import LoadIcon from '../components/LoadIcon';
import PageWithBg from '../components/PageWithBg';
import config from '../config';
import { RoomDb } from '../constants/firebaseConfig';
import { muteMusic, unmuteMusic } from '../ducks/layout';
import routes from '../routes';
import { COLORS, SIZES } from '../styles/theme';
import Amplitude from '../utils/amplitudeHelper';

const Page = styled.div`
	overflow: hidden;
	.content {
		${config.layout.auditoriumVid} position: absolute;
		background: black;
		top: 7.8%;
		bottom: 32.5%;
		left: 20.6%;
		right: 20.5%;
		iframe {
			height: 100%;
			width: 100%;
		}
	}
	.qna-container {
		position: absolute;
		left: 50%;
		transform: translate(-50%);
		background: rgba(255, 255, 255, 0.8);
		border-radius: 12px;
		font-size: .85em;
		bottom: 130px;
		${SIZES.mobile} {
			bottom: 56px;
			font-size: .7em;
		}

		header {
			display: flex;
			background: ${COLORS.primary};
			color: white;
			padding: 15px 5px 15px 20px;
			border-top-left-radius: 12px;
			border-top-right-radius: 12px;
			button {
				color: white;
				font-weight: bold;
				border: 0;
				background: none;
				width: 40px;
				cursor: pointer;
			}
		}

		form {
			position: relative;
			width: 100%;
			height: 100%;
			max-height: 100px;
			background: white;
			border-bottom-left-radius: 12px;
			border-bottom-right-radius: 12px;

			textarea {
				min-height: 80px;
				resize: none;
				outline: none;
				border: none;
				background: white;
				border-bottom-left-radius: 12px;
				border-bottom-right-radius: 12px;
				padding: 20px;
				height: 100%;
				width: 100%;
			}

			.btn {
				position: absolute;
				font-size: 12px;
				font-weight: 600;
				bottom: 10px;
				right: 10px;
				height: 40px;
				color: ${COLORS.primary};
				background: transparent;
				padding: 5px 15px;
				border: 1px solid${COLORS.primary};
				border-radius: 40px;
				svg {
					margin: 0 4px;
				}
				&:hover {
					background: ${COLORS.primary};
					color: #fff;
					svg path {
						fill: #fff;
					}
				}
			}
		}
	}
	.actions {
		display: flex;
		justify-content: center;
		position: absolute;
		bottom: 8.5%;
		right: 19.9%;
		left: 19.9%;
		button {
			background-color: #002060;
			border-radius: 32px;
			border: none;
			color: #aef3fd;
			padding: 8px 28px;
			font-style: italic;
			font-weight: 600;
			font-size: 16px;
			letter-spacing: 1px;
			svg {
				margin: 0 4px;
			}
			&:hover {
				box-shadow: 0px 10px 10px -10px #0a41a8;
				animation-name: bounce;
				animation-duration: 1s;
				animation-fill-mode: both;
			}
		}
	}
`;

export default function AuditoriumPage({ hallBg, currentUser }) {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ showForm, setShowForm ] = useState(false);
	const [ snapshots, loading ] = useList(RoomDb);
	const [ status, setStatus ] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();

	useEffect(
		() => {
			dispatch(muteMusic());

			return () => {
				if (localStorage.getItem('muted') === 'false') {
					dispatch(unmuteMusic());
				}
			};
		},
		[ dispatch ]
	);

	useEffect(
		() => {
			if (snapshots.length) {
				let hall = snapshots.filter((item) => {
					if (item.val().name === 'HP PLENARY') {
						return item;
					}
				});
				setStatus(hall[0].val().active);
				if (!hall[0].val().active) {
					if (currentUser.role !== 'superadmin') {
						history.push(routes.lobby);
						toast('The Plenary Hall is now closed.');
					}
				}
			}
			document.title = `${config.eventName} | Conference`;
		},
		[ loading ]
	);

	useEffect(
		() => {
			if (status) {
				Amplitude.visit('HP Plenary');
			}
		},
		[ currentUser.id, status ]
	);

	const displayQA = () => {
		Amplitude.clickQAndAButton();
		setShowForm(true);
	};

	const closeQA = () => {
		setShowForm(false);
	};

	return (
		<Page>
			<PageWithBg bgImg={hallBg}>
				<div className="content">
					{isLoading && <LoadIcon />}
					<iframe
						title="Live Broadcast"
						src="https://vimeo.com/event/1103136/embed"
						allow="autoplay; fullscreen"
						allowFullScreen
						frameBorder="0"
						onLoad={() => {
							setIsLoading(false);
						}}
					/>
				</div>
				<div className="actions">
					<button onClick={displayQA}>
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
							<polygon points="0 0 24 0 24 24 0 24" />
							<path
								fill="#AEF3FD"
								d="M20,2 L4,2 C2.9,2 2,2.9 2,4 L2,22 L6,18 L20,18 C21.1,18 22,17.1 22,16 L22,4 C22,2.9 21.1,2 20,2 Z M19,16 L6,16 L4,18 L4,5 C4,4.45 4.45,4 5,4 L19,4 C19.55,4 20,4.45 20,5 L20,15 C20,15.55 19.55,16 19,16 Z"
							/>
						</svg>
						<small>Ask Question</small>
					</button>
				</div>
				{showForm && (
					<div className="qna-container">
						<header>
							<div>Submit your questions anytime! We will answer your questions during Q&A Section</div>
							<button onClick={closeQA}>&#10005;</button>
						</header>
						<GoogleSheetForm toggleForm={displayQA} />
					</div>
				)}
			</PageWithBg>
		</Page>
	);
}
