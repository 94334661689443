import LogoL from './images/logo.png';
import Entrance from './images/backdrops/entrance.jpg';
import Lobby from './images/backdrops/lobby-18.jpg';
import LobbyL from './images/backdrops/lobby.jpg';
import HallA from './images/backdrops/HallA.jpg';
import HallB from './images/backdrops/HallB.jpg';
import AuditoriumAH from './images/backdrops/auditorium-ah.jpg';
import AuditoriumHP from './images/backdrops/auditorium.jpg';
import NetworkingL from './images/backdrops/networking.jpg';
import LobbyMusic from './images/audio/lobby.mp3';
import routes from './routes';
import Gallerium from './images/backdrops/gallerium.jpg';
import EventLogo from './images/backdrops/event-logo.png';
import FooterL from './images/backdrops/footerL.png';
import RegisterL from './images/backdrops/post-registration.jpg';
import RegisterP from './images/backdrops/registerP.jpg';
import CountdownL from './images/backdrops/entrance.jpg';
import CountdownP from './images/backdrops/entranceM.jpg';
import CountdownT from './images/backdrops/portraitA.jpg';

const dev = {
	apiUrl: 'http://localhost:3000'
};

const prod = {
	apiUrl: 'https://bimysg2021.herokuapp.com'
};

const API_KEYS = {
	amplitude: '2bc6ef871df2e982c03da7b224959597'
};

const eventDays = [];

const VOLUME_VALUE = 1;

const config = process.env.REACT_APP_STAGE === 'production' ? prod : dev;

const assets = {
	footer: {
		landscape: FooterL
	},
	register: {
		landscape: RegisterL,
		portrait: RegisterP
	},
	countdown: {
		logo: EventLogo,
		landscape: CountdownL,
		portrait: CountdownP,
		ipad: CountdownT
	},
	logo: {
		landscape: LogoL,
		portrait: LogoL
	},
	entrance: {
		landscape: Entrance
	},
	lobby: {
		landscape1: Lobby, // 18
		landscape2: LobbyL // 19 onwards
	},
	auditorium1: AuditoriumHP,
	auditorium2: AuditoriumAH,

	exhibition1: HallA,
	exhibition2: HallB,

	networking: {
		landscape: NetworkingL,
		portrait: NetworkingL
	},
	gallerium: Gallerium,

	lobbyMusic: LobbyMusic
};

const layout = {
	auditoriumVid: `
    top: 4%;
    bottom: 10.8%;
    left: 6.8%;
    right: 6.9%;
  `
};

const lobbyEntries = [
	{
		id: 'auditorium',
		overlayText: 'SELECT PLENARY HALL :',
		destination: [
			{ id: 1, name: 'HP PLENARY', url: routes.auditorium1 },
			{
				id: 2,
				name: 'AH PLENARY',
				url: 'https://boehringer.zoom.us/j/95130553290?pwd=RTNwZTVlbklseGg0V3VSeWRZWGZWZz09'
			}
		],
		css: `
      top: 51%;
      left: 38%;
      right: 50%;
      bottom: 40%;
    `
	},
	{
		id: 'exhibition', // destination zoom link
		overlayText: 'SELECT BREAKOUT SESSION TO ENTER:',
		destination: [
			{
				id: 1,
				activeDay: 18,
				start: 12,
				end: 18,
				name: 'Animal Health',
				url: 'https://boehringer.zoom.us/j/91065006105?pwd=SXNDd2xVTFN4SVRPMU1uRjBHLzNTdz09'
			},
			{
				id: 2,
				activeDay: 18,
				start: 12,
				end: 18,
				name: 'ANGELS',
				url: 'https://boehringer.zoom.us/j/95148516075?pwd=NU5FeElNeGJGOGNXWDhKMnJxbkxOZz09'
			},
			// close HP MIXED REPS after 1st day
			{
				id: 3,
				activeDay: 18,
				start: 12,
				end: 18,
				name: 'HP MIXED REPS',
				url: 'https://boehringer.zoom.us/j/91360508950?pwd=RkIxQVZZVnJibVpIQlVpR09OWWtRQT09'
			},
			{
				id: 4,
				activeDay: 18,
				start: 12,
				end: 18,
				name: 'METABOLIC',
				url: 'https://boehringer.zoom.us/j/99625418777?pwd=Z0pIMERDR01GczU0OHZ3bEsyTThnUT09'
			},
			{
				id: 5,
				activeDay: 22,
				start: 8,
				end: 13,
				name: 'MY BUDDY',
				url: 'https://boehringer.zoom.us/j/97755844844?pwd=WnBoR0FRSHNZekl4NTlleWNKRXJvZz09'
			},
			{
				id: 6,
				activeDay: 18,
				start: 12,
				end: 18,
				name: 'ONCOLOGY',
				url: 'https://boehringer.zoom.us/j/97498629509?pwd=aEpZSUdTSjNKOEd4Y3d6Z3hlTmpBZz09'
			}, //pending
			{
				id: 7,
				activeDay: 18,
				start: 12,
				end: 18,
				name: 'PRADAXA',
				url: 'https://boehringer.zoom.us/j/96413812563?pwd=QjMzNkFhY3lKdXdUN0Q4QUhnTFNXUT09'
			},
			{
				id: 8,
				activeDay: 18,
				start: 12,
				end: 18,
				name: 'RESPIRATORY',
				url: 'https://boehringer.zoom.us/j/8186777462?pwd=cytONkE5R1U2ZC9qeEVzZXE5ZlVMZz09'
			},
			{
				id: 9,
				activeDay: 18,
				start: 12,
				end: 18,
				name: 'TWYNSTA',
				url: 'https://boehringer.zoom.us/j/97899703377?pwd=ZDRwSk0vZzAxc00xRzYvSWdaekp1QT09'
			},
			{
				id: 10,
				activeDay: 18,
				start: 12,
				end: 18,
				name: 'OFEV',
				url: 'https://boehringer.zoom.us/j/97031688159?pwd=VnE0aDJ4c0V0RUpnb09Rb1YwS0ovdz09'
			}
		],
		css: `
      top: 51%;
      left: 76.3%;
      right: 7%;
      bottom: 40%;
    `
	},
	{
		id: 'helpdesk',
		destination: 0,
		css: `
	    top: 81.5%;
	    left: 6.5%;
	    right: 91%;
	    bottom: 18.5%;
	  `
	},
	{
		id: 'Compliance',
		css: `
			top: 22.5%;
			left: 11.4%;
			right: 73.3%;
			bottom: 87.5%;
    `
	},
	{
		id: 'Quiz',
		css: `
      top: 42.5%;
      left: 11.4%;
      right: 73.3%;
      bottom: 57.5%;
    `
	},
	{
		id: 'Korea',
		css: `
			top: 60.5%;
			left: 11.4%;
			right: 73.3%;
			bottom: 52.5%;
    `
	}
];

export default {
	assets,
	layout,
	lobbyEntries,
	API_KEYS,
	eventDays,
	VOLUME_VALUE,
	eventName: 'BI MYSG Midyear Conference 2021',
	googleAnalyticsID: '',
	...config
};
