import amplitude from 'amplitude-js';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import routes from '../routes';
import { NotificationDb } from '../constants/firebaseConfig';
import { useListVals } from 'react-firebase-hooks/database';
import { toast } from 'react-toastify';

const MobileNav = React.memo(() => {
	const history = useHistory();
	const currentUser = useSelector((state) => state.auth.currentUser);
	const [ values, loading ] = useListVals(NotificationDb);

	const [ unread, setUnread ] = useState(false);

	useEffect(
		() => {
			if (values && !loading) {
				if (values.length < localStorage.getItem('notifications')) {
					localStorage.setItem('notifications', 0);
				}
				setUnread(values.length > localStorage.getItem('notifications'));
			}
		},
		[ values, loading ]
	);

	const handleMenu = () => {
		amplitude.getInstance().logEvent('Click Agenda');
		history.push(routes.menu);
	};

	const handleNotification = () => {
		amplitude.getInstance().logEvent('Click Notifications');
		history.push(routes.notifications);
	};

	const showHelpDesk = () => {
		toast('Helpdesk has closed.');
		// const cpt = window.chaport;
		// cpt.on('ready', function() {
		// 	cpt.setVisitorData({
		// 		name: currentUser.fullName,
		// 		email: currentUser.email
		// 	});
		// 	cpt.open();
		// });
		// cpt.on('collapsed', function() {
		// 	cpt.close();
		// });
		// amplitude.getInstance().logEvent('Click Lobby Helpdesk');
	};

	return (
		<Page>
			<div className="navbar">
				<Link className="nav-item" to={routes.lobby}>
					<svg width="28.566" height="26.493" viewBox="0 0 28.566 26.493">
						<g id="Lobby" transform="translate(1.25 1.463)">
							<path
								d="M421.483,283.465H407.807a3.878,3.878,0,0,1-3.878-3.878v-9.956a4.118,4.118,0,0,1,1.4-3.1l6.7-5.867a3.97,3.97,0,0,1,5.231,0l6.7,5.867a4.117,4.117,0,0,1,1.4,3.1v9.956A3.877,3.877,0,0,1,421.483,283.465Z"
								transform="translate(-401.533 -259.685)"
								fill="none"
								stroke="#002060"
								strokeLinecap="round"
								strokeMiterlimit="10"
								strokeWidth="2.5"
							/>
							<path
								d="M8.75,0H34.816"
								transform="translate(-8.75 8.257)"
								fill="none"
								stroke="#002060"
								strokeLinecap="round"
								strokeWidth="2.5"
							/>
						</g>
					</svg>
				</Link>
				<div className="nav-item" onClick={handleMenu}>
					<svg width="28" height="26" viewBox="0 0 28 26">
						<g id="Menu" transform="translate(0.383 -0.094)">
							<g transform="translate(0 0)">
								<g transform="translate(0 0)">
									<g
										transform="translate(-0.383 0.094)"
										fill="none"
										stroke="#002060"
										strokeWidth="2.5"
									>
										<ellipse cx="14" cy="13" rx="14" ry="13" stroke="none" />
										<ellipse cx="14" cy="13" rx="12.75" ry="11.75" fill="none" />
									</g>
									<g transform="translate(8.376 8.265)">
										<path
											d="M-1571.5,262.678h10.764"
											transform="translate(1571.5 -262.678)"
											fill="none"
											stroke="#002060"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2.5"
										/>
										<path
											d="M-1571.5,262.678h10.764"
											transform="translate(1571.5 -258.28)"
											fill="none"
											stroke="#002060"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2.5"
										/>
										<path
											d="M-1571.5,262.678h10.764"
											transform="translate(1571.5 -253.882)"
											fill="none"
											stroke="#002060"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2.5"
										/>
									</g>
								</g>
							</g>
						</g>
					</svg>
				</div>
				<div className="nav-item" onClick={handleNotification}>
					<svg
						width="25.873"
						height="26.384"
						viewBox="0 0 25.873 26.384"
						className={unread ? 'with-shake' : ''}
					>
						<g transform="translate(1.25 1.25)">
							<g transform="translate(0)">
								<path
									d="M226.041,376.761v7.8a4.071,4.071,0,0,1-4.093,4.047H207.239a4.071,4.071,0,0,1-4.094-4.047V370.134a4.072,4.072,0,0,1,4.094-4.048h5.892"
									transform="translate(-203.145 -364.721)"
									fill="none"
									stroke="#002060"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2.5"
								/>
								<ellipse
									cx="5.297"
									cy="5.297"
									rx="5.297"
									ry="5.297"
									transform="translate(12.779)"
									fill={unread ? 'red' : 'none'}
									stroke={unread ? 'red' : '#002060'}
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2.5"
								/>
							</g>
						</g>
					</svg>
				</div>
				<div className="nav-item" onClick={showHelpDesk}>
					<svg width="26.617" height="28.228" viewBox="0 0 26.617 28.228">
						<g transform="translate(1320 -518)">
							<g transform="translate(-1320 518)" fill="none">
								<path
									d="M13.309,0a13.309,13.309,0,0,1,0,26.617c-.97,0-5.323,1.837-6.468,1.587-1.117-.241,1.22-2.577,0-3.224A13.334,13.334,0,0,1,13.309,0Z"
									stroke="none"
								/>
								<path
									d="M 13.30859375 2.5 C 10.4215145111084 2.5 7.707242965698242 3.624290466308594 5.665763854980469 5.665760040283203 C 3.624294281005859 7.707239151000977 2.500003814697266 10.42151069641113 2.500003814697266 13.30858993530273 C 2.500003814697266 15.31649971008301 3.049104690551758 17.28569984436035 4.087944030761719 19.00331115722656 C 5.075374603271484 20.63591957092285 6.430454254150391 21.93798065185547 8.006692886352539 22.76873016357422 L 8.009313583374023 22.77012062072754 L 8.011934280395508 22.77151107788086 C 8.906669616699219 23.24579429626465 9.487831115722656 24.06800651550293 9.654182434082031 25.0355396270752 C 9.799606323242188 24.98918914794922 9.935602188110352 24.94549942016602 10.05693435668945 24.90652084350586 C 11.67144393920898 24.38784980773926 12.54930400848389 24.11718940734863 13.30859375 24.11718940734863 C 16.19567489624023 24.11718940734863 18.90994262695312 22.99290084838867 20.95142364501953 20.95142936706543 C 22.99289321899414 18.90995025634766 24.11718368530273 16.19567108154297 24.11718368530273 13.30858993530273 C 24.11718368530273 10.42151069641113 22.99289321899414 7.707239151000977 20.95142364501953 5.665760040283203 C 18.90994262695312 3.624290466308594 16.19567489624023 2.5 13.30859375 2.5 M 13.30859375 0 C 20.65872383117676 0 26.61718368530273 5.958459854125977 26.61718368530273 13.30858993530273 C 26.61718368530273 20.65872955322266 20.65872383117676 26.61718940734863 13.30859375 26.61718940734863 C 12.40019702911377 26.61718940734863 8.52650260925293 28.22771835327148 7.097040176391602 28.22771835327148 C 6.999753952026367 28.22771835327148 6.913993835449219 28.22028160095215 6.841053009033203 28.20435905456543 C 5.723903656005859 27.96310043334961 8.061193466186523 25.62714004516602 6.841053009033203 24.98036003112793 C 2.916374206542969 22.9118595123291 3.814697265625e-06 18.4507999420166 3.814697265625e-06 13.30858993530273 C 3.814697265625e-06 5.958459854125977 5.958463668823242 0 13.30859375 0 Z"
									stroke="none"
									fill="#002060"
								/>
							</g>
							<path
								d="M-1297.329,538H-1307.5s1.227,4.16,5.085,4.16S-1297.329,538-1297.329,538Z"
								transform="translate(-2.909 -4.399)"
								fill="#002060"
							/>
						</g>
					</svg>
				</div>
			</div>
		</Page>
	);
});

const Page = styled.div`
	width: 100vw;
	height: 100px;
	position: absolute;
	bottom: 0;
	left: 0;
	display: inline-block;
	background: #f8f4ff;

	.navbar {
		width: 100%;
		height: 60px;
		position: absolute;
		bottom: 0;
		left: 0;

		display: flex;
		justify-content: space-around;
		align-items: center;
	}

	.nav-item {
		width: 50px;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		svg {
			width: 26px;
			height: 26px;
		}
	}
`;

export default MobileNav;
