import Axios from 'axios';
import queryString from 'query-string';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import config from '../../config';
import routes from '../../routes';
import { COLORS, SIZES } from '../../styles/theme';

const Page = styled.div`
	background: url(${config.assets.entrance.landscape}) no-repeat top center;
	background-size: cover;
	display: block;
	position: fixed;
	min-height: 100%;
	width: 100vw;

	${SIZES.mobile} {
		background: url(${config.assets.countdown.portrait}) no-repeat top;
		background-size: 100% 100%;
		min-height: 100%;
		width: 100vw;
		position: fixed;
		display: block;
		margin: 0 auto;

		@media screen and (min-width: 540px) {
			background: url(${config.assets.countdown.ipad});
			background-size: cover;
		}
	}

	.reset-form {
		position: fixed;
		bottom: 5vh;
		right: 0vw;
		padding: 4vw;
		padding-bottom: 0;
		max-height: 100%;
		text-align: center;
		color: white;
		${SIZES.mobile} {
			bottom: 10%;
			top: 40%;
			padding: 20% 10px;
			min-height: 100vh;
			max-height: auto;

			@media screen and (min-width: 540px) {
				bottom: 5vh;
			}
		}
		h4 {
			color: #404040;
		}
		form {
			background: #fff;
			padding: 30px 10px;
			border-radius: 28px;
			margin: auto;
			margin-top: 30px;
			max-width: 400px;

			${SIZES.mobile} {
				margin-top: -80px;
				padding: 20px 10px;
				@media screen and (min-width: 768px) {
					margin-top: 30px;
				}
			}

			.form-input {
				width: 80%;
				background: ${COLORS.lightGray};
				border: none;
				padding: 10px 20px;
				border-radius: 25px;
				margin-top: 20px;

				&::placeholder {
					color: ${COLORS.lightGray1};
					${SIZES.mobile} {
						font-size: 12px;
					}
				}
			}
		}
	}
	a,
	button.submit-btn {
		color: #ffffff;
		width: 80%;
		font-weight: 500;
		border-radius: 56px;
		display: flex;
		justify-content: space-between;
		padding: 4px 30px;
		margin: auto;
		margin-top: 3em;
		margin-bottom: 1em;
		font-size: 1em;
		outline: none;
		background: #ce2440;
		border: 2px solid #fff;
		box-shadow: 0px 5px 4px -4px #000;
		font-size: 12px;

		&:disabled {
			cursor: not-allowed;
			background-color: grey;
		}

		div {
			line-height: 32px;
		}
		div.arrow {
			line-height: 24px;
			font-size: 1.5em;
			align-self: flex-end;
		}
	}
`;

export default function SetPasswordFormPage() {
	const location = useLocation();
	const history = useHistory();

	const [ loading, setLoading ] = useState(false);
	const [ password, setPassword ] = useState('');
	const [ passwordConfirmation, setPasswordConfirmation ] = useState('');

	const handleSubmit = (e) => {
		e.preventDefault();
		setLoading(true);

		Axios.post(
			`${config.apiUrl}/api/v1/create_password`,
			{
				password
			},
			{
				headers: {
					Authorization: `Bearer ${queryString.parse(location.search).token}`
				}
			}
		)
			.then(() => {
				setLoading(false);
				toast('Your password is successfully created!');
				history.push(routes.root);
			})
			.catch((err) => {
				setLoading(false);
				toast(err.response.data.message);
			});
	};

	const hasEmptyField = !(password.length && passwordConfirmation.length && password === passwordConfirmation);

	return (
		<Page>
			<div className="reset-form">
				<form onSubmit={handleSubmit}>
					<h4>Create Password</h4>
					<input
						className="form-input"
						required
						type="password"
						value={password}
						placeholder="Password(min 8 characters)"
						onChange={(e) => setPassword(e.target.value)}
					/>
					<input
						className="form-input"
						required
						type="password"
						placeholder="Password Confirmation"
						value={passwordConfirmation}
						onChange={(e) => setPasswordConfirmation(e.target.value)}
					/>
					<div>
						{password.length < 8 && (
							<p className="text-danger text-center mb-0 mt-2">
								<em>
									<small>Your password should be min 8 characters</small>
								</em>
							</p>
						)}
						{hasEmptyField && (
							<p className="text-danger text-center">
								<em>
									<small>Please make sure both password matches</small>
								</em>
							</p>
						)}
						<Button
							type="submit"
							className="submit-btn"
							variant="default"
							disabled={hasEmptyField || loading}
							onSubmit={handleSubmit}
						>
							<div>{loading ? 'Loading...' : 'CREATE PASSWORD'}</div>
							<div className="arrow">⇀</div>
						</Button>
					</div>
				</form>
			</div>
		</Page>
	);
}
