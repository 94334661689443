import { useEffect } from 'react';
import amplitude from 'amplitude-js';
import { useDuplicateSession } from '../utils/sessionHelper';
import { usePageUserCount } from '../utils/usePageUserCount';
import { useUserSession } from '../utils/useUserSession';
import { useOnlineStatus } from '../utils/useOnlineStatus';

export default function AuthContainer({ currentUser, children }) {
	useEffect(
		() => {
			amplitude.getInstance().setUserId(currentUser.email);
			amplitude.getInstance().setUserProperties({ ...currentUser });
		},
		[ currentUser ]
	);

	useDuplicateSession(currentUser);
	usePageUserCount(currentUser);
	useUserSession(currentUser);
	useOnlineStatus(currentUser);

	return children;
}
