import React, { useEffect, useState } from 'react';
import { useList } from 'react-firebase-hooks/database';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import ChatSection from '../../components/ChatSection.js';
import LoadIcon from '../../components/LoadIcon';
import config from '../../config';
import { RoomDb } from '../../constants/firebaseConfig';
import { muteMusic, unmuteMusic } from '../../ducks/layout';
import BgImgI from '../../images/backdrops/auditoriumIpad.png';
import BgImgM from '../../images/mobile/auditoriumM.png';
import closeTheatre from '../../images/mobile/close-theatre.png';
import openTheatre from '../../images/mobile/open-theatre.png';
import screen from '../../images/mobile/screen.png';
import routes from '../../routes';
import Amplitude from '../../utils/amplitudeHelper';

const MobileKFair20 = (props) => {
	const { currentUser } = props;
	const dispatch = useDispatch();
	const [ isLoading, setIsLoading ] = useState(true);
	const [ theatre, setTheatre ] = useState(false);
	const [ snapshots, loading, error ] = useList(RoomDb);
	const [ status, setStatus ] = useState(false);
	const history = useHistory();

	useEffect(
		() => {
			if (status) {
				Amplitude.visit('HP Plenary');
			}
		},
		[ currentUser.id, status ]
	);

	useEffect(
		() => {
			dispatch(muteMusic());

			return () => {
				if (localStorage.getItem('muted') === 'false') {
					dispatch(unmuteMusic());
				}
			};
		},
		[ dispatch ]
	);

	useEffect(
		() => {
			if (snapshots.length) {
				let hall = snapshots.filter((item) => {
					if (item.val().name === 'HP PLENARY') {
						return item;
					}
				});
				setStatus(hall[0].val().active);
				if (!hall[0].val().active) {
					if (currentUser.role !== 'superadmin') {
						history.push(routes.lobby);
						toast('The Plenary Hall is now closed.');
					}
				}
			}
			document.title = `${config.eventName} | Conference`;
		},
		[ loading ]
	);

	const videoContainerTheatreStyle = {
		width: '100%',
		height: '100%',
		position: 'fixed',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		background: 'black',
		zIndex: '99'
	};
	if (loading || error) {
		return <div />;
	}
	return (
		<div>
			<Page>
				<div className="outer-wrapper">
					<div className="container">
						<div className="screen-container mt-5">
							<img src={screen} className="screen" alt="screen" />
							<div className="video-container" style={theatre ? videoContainerTheatreStyle : {}}>
								{isLoading && <LoadIcon />}
								<iframe
									title="Live Broadcast"
									src="https://vimeo.com/event/1103136/embed"
									allow="autoplay; fullscreen"
									allowFullScreen
									frameBorder="0"
									onLoad={() => {
										setIsLoading(false);
									}}
								/>
							</div>
						</div>

						<button type="button" className="theatre-button" onClick={() => setTheatre(true)}>
							<img src={openTheatre} className="theatre-button-icon" alt="button" />
							<p className="theatre-button-label">Theatre mode</p>
						</button>
					</div>
					<div className="chat-container">
						<ChatSection currentUser={currentUser} hallName="MainAuditorium" />
					</div>
					{theatre && (
						<div className="theatre-close">
							<img
								src={closeTheatre}
								onClick={() => setTheatre(false)}
								className="theatre-close-icon"
								alt="close-icon"
							/>
						</div>
					)}
				</div>
			</Page>
		</div>
	);
};

const Page = styled.div`
	background: url(${BgImgM}) no-repeat;
	background-size: cover;
	background-position: 50% 50%;
	height: calc(100% - 60px);
	width: 100vw;
	overflow-y: scroll;
	border-radius: 0px 0px 25px 25px;
	position: absolute;
	@media screen and (min-width: 768px) {
		background: url(${BgImgI}) no-repeat center center;
		background-size: cover;
	}

	.outer-wrapper {
		width: 100%;
	}

	.container {
		width: 95%;
		margin: auto;
	}

	.checkin-container {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin: 30px 0px 20px 0px;
	}

	.checkin {
		width: 38%;
		height: auto;
	}

	.screen-container {
		width: 100%;
		height: 0;
		padding-bottom: 58.5%;
		position: relative;
	}

	.screen {
		top: 2vh;
		width: 100%;
		position: absolute;
		@media screen and (min-width: 768px) {
			top: 5vh;
			width: 100%;
			position: absolute;
		}
	}

	.video-container {
		width: 92%;
		height: 81%;
		background: #1e1e1e;
		position: absolute;
		top: 58%;
		left: 50%;
		transform: translate(-50.5%, -51%);
		@media screen and (min-width: 768px) {
			top: 62.5%;
			left: 50%;
			position: absolute;
		}

		iframe {
			height: 100%;
			width: 100%;
		}
	}

	.title {
		width: 100%;
		margin: 0px;
	}

	.chat-container {
		margin: 80px 0;
		width: 100%;
		height: 275px;
		@media screen and (min-width: 768px) {
			margin: 180px 0;
			width: 100%;
			height: 275px;
		}
	}

	.theatre-button {
		width: 140px;
		height: 30px;
		background: black;
		margin: 30px 10px 0px 0px;
		float: right;
		border: none;
		border-radius: 5px;
		display: flex;
		justify-content: space-around;
		align-items: center;
		/* opacity: 0.6; */
		padding: 0;
		@media screen and (min-width: 768px) {
			margin: 100px 10px 0px 0px;
		}
	}

	.theatre-button-label {
		color: white;
		font-size: 12px;
		margin: 0px 10px 0px 0px;
	}

	.theatre-button-icon {
		width: 20px;
		margin: 0px 10px;
	}

	.theatre-close {
		color: white;
		background: black;
		width: 30px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 10px;
		position: fixed;
		top: 10px;
		left: 10px;
		z-index: 99;
		opacity: 0.6;
	}

	.theatre-close-icon {
		width: 20px;
	}

	@media screen and (min-width: 540px) {
		.outer-wrapper {
			/* width: 540px; */
			margin: auto;
		}
	}
`;

export default MobileKFair20;
