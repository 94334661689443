import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useListVals } from 'react-firebase-hooks/database';
import ReactTimeAgo from 'react-time-ago';
import styled from 'styled-components';
import { NotificationDb } from '../../constants/firebaseConfig';
import { COLORS } from '../../styles/theme';
import LoadIcon from '../LoadIcon';

const StyledModal = styled(Modal)`
  color: ${COLORS.primary};
  text-align: left;
  /* mix-blend-mode: hard-light; */
  
  .modal-content {
    background-color: rgba(255, 255, 255, 0.9);
    opacity: 0.94;
    border: none;
    border-radius: 0;
    padding: 24px 72px;
    margin-bottom: 24px;

    .modal-header {
      border: none;
      .modal-title {
        font-size: 30px;
        font-weight: bold;
        font-style: italic;
      }
      button {
        font-size: 36px;
        opacity: 1;
        color: ${COLORS.primary};;
      }
    }
  }
  .divider {
    background: ${COLORS.primary};
    height: 4.5px;
  }
  .modal-body {
    padding-bottom: 36px;
    section {
      margin-bottom: 36px;
      h4 {
        font-style: italic;
        font-size: 23px;
        font-weight: bold;
      }
    }
  }
  #refresh-btn {
    border: solid 1px ${COLORS.primary};
    background: #FFAD2C;
    padding: 5px 10px;
    border-radius: 16px;
    margin: 36px auto;
    display: block;
  }
`;
const MessageWrapper = styled.div`
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: ${(props) => (props.read ? 'transparent' : 'rgba(197, 227, 246, 0.5)')};
	border-bottom: 3px solid rgba(197, 160, 252, 0.5);

	div.text-content {
		width: 85%;
		p {
			color: rgba(0, 0, 0, 0.55);
			font-size: 16px;
			font-weight: 500;
			margin: 0;
		}
		small.timestamp {
			font-size: 0.8em;
			color: rgba(0, 0, 0, 0.55);
			margin-bottom: 16px;
		}
	}
	div.btn-container {
		width: 15%;
		padding: 0px 24px;
		text-align: center;
		button.action-btn {
			background: #002060;
			color: #ffffff;
			text-decoration: none;
			border-radius: 12px;
			padding: 6px 16px;
			font-weight: 500;
			border: none;
		}
	}
`;
export default function NotificationModal({ showModal, hideSchedule }) {
	const [ values, loading, error ] = useListVals(NotificationDb);
	const [ notification, setNotification ] = useState(null);

	const refreshPage = () => {
		window.location.reload();
	};

	useEffect(
		() => {
			const newItems = values.length - localStorage.getItem('notifications');
			let updated = values.sort((a, b) => new Date(b.datetime) - new Date(a.datetime)).map((item, index) => {
				return {
					...item,
					read: index < newItems ? false : true
				};
			});
			setNotification(updated);
			if (values.length > localStorage.getItem('notifications')) {
				localStorage.setItem('notifications', values.length);
			}
		},
		[ loading, values ]
	);

	return (
		<StyledModal show={showModal} onHide={hideSchedule} size="xl" aria-labelledby="notification-modal" centered>
			<Modal.Header closeButton>
				<Modal.Title>NOTIFICATIONS</Modal.Title>
			</Modal.Header>
			<div className="divider" />
			<Modal.Body>
				<section>
					{loading ? (
						<LoadIcon />
					) : error ? (
						<button id="refresh-btn" onClick={refreshPage}>
							Connection failed. Click here to refresh.
						</button>
					) : (
						notification.map((item, index) => {
							return (
								<MessageWrapper key={`notify-${index}`} read={item.read}>
									<div className="text-content">
										<p>{item.message}</p>
										<small className="d-block timestamp">
											{item.datetime && <ReactTimeAgo date={item.datetime} />}
										</small>
									</div>
									<div className="btn-container" />
								</MessageWrapper>
							);
						})
					)}
				</section>
			</Modal.Body>
		</StyledModal>
	);
}
