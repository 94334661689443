import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Amplitude from './utils/amplitudeHelper';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-modal-video/css/modal-video.min.css';
import 'react-phone-input-2/lib/style.css';

import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from './Store';
import GlobalStyle from './styles/global';

Amplitude.init();

ReactDOM.render(
	<React.StrictMode>
		<GlobalStyle />
		<ToastContainer
			hideProgressBar
			draggable
			pauseOnHover
			// autoClose={3000}
		/>
		<Provider store={configureStore()}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
