import React, { useEffect, useMemo } from 'react';
import Media from 'react-media';
import { Route, Switch } from 'react-router-dom';
import config from './config';
import EntrancePages from './containers/EntrancePages';
import PrivateRoute from './containers/PrivateRoute';
import MobileComplianceVideo from './mobileOnlyPages/MobileComplianceVideo/index.js';
import MobileExpoDirectory from './mobileOnlyPages/MobileExpo/MobileExpoDirectory.js';
import MobileKFair20 from './mobileOnlyPages/MobileKFair20/index.js';
import MobileKoreaVideo from './mobileOnlyPages/MobileKoreaVideo/index.js';
import MobileLobby from './mobileOnlyPages/MobileLobby/index.js';
import MobileMenu from './mobileOnlyPages/MobileMenu/index.js';
import MobileNotifications from './mobileOnlyPages/MobileNotifications/index.js';
import ActiveUserCountPage from './pages/ActiveUserCountPage';
import PostRegisterPage from './pages/registration/PostRegisterPage';
import RegisterFormPage from './pages/registration/RegisterFormPage';
import SetPasswordFormPage from './pages/registration/SetPasswordFormPage';
import UserSessionPage from './pages/UserSessionPage';
import routes from './routes';
import { SIZES } from './styles/theme';
import AuditoriumPage from './webOnlyPages/AuditoriumPage';
import LobbyPage from './webOnlyPages/LobbyPage';

function App() {
	useEffect(() => {
		document.title = config.eventName;
	}, []);

	useEffect(() => {
		window.chaport.q('hideLauncher');
	}, []);

	return useMemo(() => {
		return (
			<div>
				{/* Registration */}
				<Switch>
					{/* <Route exact path={routes.root} component={CountdownPage} /> */}
					<Route exact path={routes.root} component={EntrancePages} />
					<Route exact path={routes.register} component={RegisterFormPage} />
					<Route exact path={routes.resetPassword} component={SetPasswordFormPage} />
					<Route exact path={routes.postRegister} component={PostRegisterPage} />
					<Route exact path={routes.login} component={EntrancePages} />
					<Route exact path="/realtime" component={ActiveUserCountPage} />
					<Route exact path="/sessions" component={UserSessionPage} />
				</Switch>

				<Media query={`(max-width: ${SIZES.mobileBreakpoint})`}>
					{(matches) =>
						matches ? (
							<Switch>
								{/* Lobby */}
								<PrivateRoute path={routes.lobby} component={MobileLobby} mobileNav />
								{/* HP plenary */}
								<PrivateRoute
									mobileNav
									path={routes.auditorium1}
									component={(props) => <MobileKFair20 {...props} />}
								/>
								{/* Breakout session directory */}
								<PrivateRoute
									mobileNav
									path={routes.exhibition1}
									component={(props) => <MobileExpoDirectory {...props} />}
								/>

								{/* Korea video directory */}
								<PrivateRoute
									mobileNav
									path={routes.korea}
									component={(props) => <MobileKoreaVideo {...props} />}
								/>

								{/* Compliance video directory */}
								<PrivateRoute
									mobileNav
									path={routes.compliance}
									component={(props) => <MobileComplianceVideo {...props} />}
								/>

								{/* Menu */}
								<PrivateRoute mobileNav path="/menu" component={MobileMenu} />

								{/* Notifications */}
								<PrivateRoute path={routes.notifications} component={MobileNotifications} />
							</Switch>
						) : (
							<Switch>
								<PrivateRoute fixedNav path={routes.lobby} component={LobbyPage} />

								{/* Auditorium (Vimeo) Hall */}
								<PrivateRoute
									fixedNav
									path={routes.auditorium1}
									component={(props) => (
										<AuditoriumPage {...props} hallBg={config.assets.auditorium1} />
									)}
								/>
							</Switch>
						)}
				</Media>
			</div>
		);
	}, []);
}

export default App;
