import React, { useEffect, useState } from 'react';
import { useList } from 'react-firebase-hooks/database';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { RoomDb } from '../../constants/firebaseConfig';
import linkIcon from '../../images/mobile/link.png';
import routes from '../../routes';
import Amplitude from '../../utils/amplitudeHelper';

const MobileExpoDirectory = ({ currentUser }) => {
	const [ snapshots, loading, error ] = useList(RoomDb);
	const [ boothList, setBoothList ] = useState([]);
	const notBreakout = [ 'HP PLENARY', 'AH PLENARY' ];

	useEffect(
		() => {
			let entries = [];
			for (let i = 0; i < snapshots.length; i++) {
				if (!notBreakout.includes(snapshots[i].val().name)) {
					entries = [
						...entries,
						{
							name: snapshots[i].val().name,
							active: snapshots[i].val().active,
							url: snapshots[i].val().url
						}
					];
				}
			}
			setBoothList(entries);
		},
		[ loading ]
	);

	const handleOpenLink = (item) => {
		if (item.active || currentUser.role === 'superadmin') {
			Amplitude.clickBreakOutSession(item.name);
			window.open(item.url, '_blank');
		} else {
			toast('The Breakout Room is open at 8:00 am.');
		}
	};

	if (loading || error) {
		return <div />;
	}
	return (
		<div>
			<Page>
				<div className="outer-wrapper">
					<div className="container">
						<div className="title-container">
							<p className="title">Breakout Sessions</p>
							<Link className="back-button" to={routes.lobby}>
								Back
							</Link>
						</div>
						{boothList.map((item, index) => {
							if (item) {
								return (
									<div className="item-wrapper" key={index} onClick={() => handleOpenLink(item)}>
										<p className="item-title">
											{item.name}
											{item.active && (
												<span className="badge badge-pill badge-danger ml-2">LIVE</span>
											)}
										</p>
										<img src={linkIcon} className="item-icon" alt="link-icon" />
									</div>
								);
							}
						})}
					</div>
				</div>
			</Page>
		</div>
	);
};

const Page = styled.div`
	background: white;
	height: calc(100% - 60px);
	width: 100vw;
	overflow-y: scroll;
	border-radius: 0px 0px 25px 25px;
	position: absolute;

	a {
		color: black;
	}

	.outer-wrapper {
		width: 100%;
	}

	.container {
		width: 90%;
		margin: auto;
	}

	.title-container {
		margin: 20px 0px 10px 0px;
		height: 50px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.title {
		width: 70%;
		font-size: 22px;
		font-weight: bold;
		font-style: italic;
		color: black;
		margin: 0;
	}

	.back-button {
		font-size: 12px;
		color: #312384;
		font-weight: bold;
	}

	.search-wrapper {
		width: 100%;
		height: 40px;
		border: solid 3px #662283;
		border-radius: 30px;
		padding: 0px 10px 0px 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;
	}

	.search-input {
		width: 70%;
		font-size: 14px;
		border: none;
	}

	.search-icon {
		height: 20px;
	}

	.item-wrapper {
		width: 100%;
		padding: 0px 20px 0px 0px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 30px;
		cursor: pointer;
	}

	.item-title {
		width: 80%;
		font-size: 15px;
		margin: 0;
	}

	.item-icon {
		height: 20px;
	}

	@media screen and (min-width: 540px) {
		.outer-wrapper {
			/* width: 540px; */
			margin: auto;
		}
	}
`;

export default MobileExpoDirectory;
