import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import ForgetPassword from '../components/ForgetPassword';
import config from '../config';
import { loginUser } from '../ducks/auth';
import routes from '../routes';
import { COLORS, SIZES } from '../styles/theme';

const LoginBox = styled.section`
	position: fixed;
	bottom: 5vh;
	right: 0vw;
	padding: 4vw;
	padding-bottom: 0;
	max-height: 100%;
	text-align: center;
	color: white;

	.action-text {
		font-weight: bold;
		color: #279aac;
		cursor: pointer;
	}
	form {
		background: #fff;
		color: #404040;
		padding: 30px 10px;
		border-radius: 28px;
		margin: auto;
		margin-top: 30px;
		max-width: 400px;
		input {
			width: 80%;
			background: ${COLORS.lightGray};
			border: none;
			padding: 12px 28px;
			border-radius: 20px;
			margin-top: 20px;
			&::placeholder {
				color: ${COLORS.lightGray1};
			}
		}
		.forget-pass {
			width: 80%;
			color: ${COLORS.primaryTrans};
			margin-top: 10px;
			font-size: 0.9em;
		}
		.login-btn {
			width: 80%;
			margin: auto;
			margin-top: 0.5em;
			margin-bottom: 0.5em;
			color: white;
			padding: 4px 30px;
			border-radius: 50px;
			letter-spacing: 1px;
			background: ${COLORS.primary};
			display: flex;
			justify-content: space-between;
			div {
				line-height: 32px;
			}
			div.arrow {
				line-height: 24px;
				font-size: 1.5em;
				align-self: flex-end;
			}
		}
	}

	${SIZES.mobile} {
		bottom: 10%;
		top: 40%;
		padding: 20% 10px;
		min-height: 100vh;
		max-height: auto;
		/* @media (min-width: 768px) {
			left: 0%;
		}
		@media (min-width: 1024px) {
			top: 25%;
		} */

		form {
			margin-top: -40px;
			border-radius: 30px;
			@media screen and (min-width: 768px) {
				margin-top: 30px;
			}
		}
	}
`;

export default function LoginPage() {
	const [ password, setPassword ] = useState('');
	const [ email, setEmail ] = useState('');

	const { isFetching, isAuthenticated } = useSelector((state) => state.auth);
	const dispatch = useDispatch();

	const handleLogin = (e) => {
		e.preventDefault();
		dispatch(
			loginUser({
				email,
				password
			})
		);
	};
	const onPasswordInput = (e) => {
		setPassword(e.target.value);
	};

	const onEmailInput = (e) => {
		setEmail(e.target.value.toLowerCase());
	};

	useEffect(() => {
		document.title = `${config.eventName} | Login`;
	}, []);

	if (isAuthenticated) {
		return <Redirect to={routes.lobby} />;
	} else {
		return (
			<LoginBox>
				<form onSubmit={handleLogin}>
					<h4 className="title">Login</h4>
					<input type="email" placeholder="Email Address" value={email} onChange={onEmailInput} />
					<input type="password" value={password} placeholder="Password" onChange={onPasswordInput} />
					<ForgetPassword>
						<small className="text-secondary font-weight-bold">Forgot Password?&nbsp;</small>
						<small className="action-text">Click Here</small>
					</ForgetPassword>

					<button
						disabled={!(email.length && password.length) || isFetching}
						type="submit"
						className="btn login-btn"
					>
						<div>{isFetching ? 'LOGGING IN...' : 'LOGIN'}</div>
						<div className="arrow">⇀</div>
					</button>
				</form>
			</LoginBox>
		);
	}
}
