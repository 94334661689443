import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, useHistory } from 'react-router-dom'
import BackgroundAudio from '../components/BackgroundAudio'
import ModalContainer from '../components/GlobalModals/ModalContainer'
import LoadIcon from '../components/LoadIcon'
import MobileNav from '../mobileOnlyPages/MobileNav'
import NoticeBar from '../components/NoticeBar'
import TopNav from '../components/TopNav'
import { refreshUser } from '../ducks/auth'
import routes from '../routes'
import AuthContainer from './AuthContainers'

export default function PrivateRoute(props) {
  const history = useHistory()
  const { fixedNav = false, mobileNav = false, component: Component, navOptions, navColor, ...rest } = props
  const dispatch = useDispatch()

  const { currentUser, appReady, isAuthenticated } = useSelector((state) => state.auth)

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(refreshUser())
    } else {
      history.push(routes.login)
    }
  }, [dispatch, history, isAuthenticated])

  if (!appReady) return <LoadIcon />

  return (
    <Route
      exact
      {...rest}
      render={(props) => (
        <AuthContainer currentUser={currentUser}>
          <ModalContainer currentUser={currentUser} />
          {fixedNav && (
            <>
              <TopNav fixedNav={fixedNav} navColor={navColor} currentUser={currentUser} />
              <NoticeBar />
            </>
          )}
          {mobileNav && <MobileNav />}
          <Component currentUser={currentUser} {...props} />
          <BackgroundAudio />
        </AuthContainer>
      )}
    />
  )
}
