import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import LobbyEntry from '../components/LobbyEntry';
import LobbyOverlay from '../components/LobbyOverlay';
import PageWithBg from '../components/PageWithBg';
import config from '../config';
import Amplitude from '../utils/amplitudeHelper';
import { toast } from 'react-toastify';

const PageWrapper = styled.div`
	.trailer {
		background-color: black;
		position: absolute;
		top: 13.5%;
		left: 33.7%;
		right: 34.7%;
		bottom: 54.9%;
	}
`;

export default function LobbyPage({ currentUser }) {
	const [ overlayTitle, setOverlayTitle ] = useState('');
	const [ overlay, setOverlay ] = useState(null);

	const showEntryOverlay = (destinations) => {
		setOverlay(destinations);
	};

	const hideOverlay = () => setOverlay(null);

	const escFunction = useCallback((event) => {
		if (event.keyCode === 27) {
			hideOverlay();
		}
	}, []);

	const showHelpDesk = () => {
		// const cpt = window.chaport;
		// cpt.on('ready', function() {
		// 	cpt.setVisitorData({
		// 		name: currentUser.fullName,
		// 		email: currentUser.email
		// 	});
		// 	cpt.open();
		// });
		// cpt.on('collapsed', function() {
		// 	cpt.close();
		// });
		// Amplitude.clickHelpDesk();
		toast('Helpdesk has closed.');
	};

	useEffect(() => {
		document.title = `${config.eventName} | Lobby`;
	}, []);

	useEffect(
		() => {
			Amplitude.visit('Lobby');
		},
		[ currentUser.id ]
	);

	useEffect(
		() => {
			document.addEventListener('keydown', escFunction, false);

			return () => {
				document.removeEventListener('keydown', escFunction, false);
			};
		},
		[ escFunction ]
	);

	return (
		<PageWrapper>
			<PageWithBg bgImg={config.assets.lobby.landscape2}>
				<LobbyOverlay
					overlayTitle={overlayTitle}
					overlay={overlay}
					hideOverlay={hideOverlay}
					currentUser={currentUser}
				/>
				{config.lobbyEntries.map((entry) => {
					return (
						<LobbyEntry
							showHelpDesk={showHelpDesk}
							key={entry.id}
							setOverlayTitle={setOverlayTitle}
							showEntryOverlay={showEntryOverlay}
							{...entry}
						/>
					);
				})}
			</PageWithBg>
		</PageWrapper>
	);
}
