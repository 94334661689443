import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { firebaseDb } from '../constants/firebaseConfig';

const Page = styled.div`
	background: #2e3188;
	min-height: 100vh;
	width: 100vw;
	overflow: hidden;
	display: flex;
	justify-content: center;
	padding: 50px;

	#board {
		background-color: white;
		border-radius: 24px;
		padding: 32px;
		width: 35%;
		h4 {
			text-decoration: underline;
			margin: 8px auto;
		}
		span {
			background-color: red;
			color: white;
			border-radius: 4px;
			padding: 2px 4px;
		}
	}
`;

export default function ActiveUserCountPage() {
	const envDbRef = `${process.env.REACT_APP_STAGE === 'production' ? 'production' : 'local'}/userCount`;

	const [ pageData, setPageData ] = useState([]);
	const [ boothData, setBoothData ] = useState([]);

	useEffect(
		() => {
			const ref = firebaseDb.ref(envDbRef);
			ref.on(
				'value',
				function(snapshot) {
					const data = snapshot.val() || {};

					setPageData(
						Object.keys(data).map((key) => ({
							pageName: key,
							pageCount: Object.keys(data[key]).length
						}))
					);
					if (data.booths) {
						setBoothData(
							Object.keys(data.booths).map((key) => ({
								pageName: key,
								pageCount: Object.keys(data.booths[key]).length
							}))
						);
					}
				},
				function(errorObject) {
					console.log('The read failed: ' + errorObject.code);
				}
			);
		},
		[ envDbRef ]
	);

	return (
		<Page>
			<div id="board">
				<h4>Active Users In Hall</h4>
				{pageData.map(
					({ pageName, pageCount }) =>
						pageName !== 'booths' && (
							<h5 key={pageName}>
								{pageName} : <span>{pageCount}</span>
							</h5>
						)
				)}
				{boothData.length > 0 &&
					boothData.map(({ pageName, pageCount }) => (
						<h5 key={pageName}>
							Booth {pageName} : <span>{pageCount}</span>
						</h5>
					))}
			</div>
		</Page>
	);
}
