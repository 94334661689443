import React, { useState } from 'react';
import styled from 'styled-components';
import GoogleSheetForm from './GoogleSheetForm';
// import ChatCard from './Networking/ChatCard'
// import { ChatDb } from '../constants/firebaseConfig'
// import { useList } from 'react-firebase-hooks/database'
import { COLORS } from '../styles/theme';
// import LoadIcon from './LoadIcon'

const ChatContainer = styled.div`
	width: 100%;
	height: 100%;
	/* backdrop-filter: blur(10px); */
	background-color: rgba(255, 255, 255, 0.65);
	color: #fff;
	padding: 32px 16px;

	h4 {
		text-align: center;
		margin: 16px;
	}
	.tabs {
		display: flex;
		justify-content: space-between;
		padding: 8px;

		.active {
			svg path {
				fill: #279aac;
			}
			small {
				color: #279aac;
				font-style: italic;
			}
		}
		button {
			background: transparent;
			border: none;
			svg path {
				fill: #fff;
			}
			small {
				padding: 0 4px;
				font-weight: 600;
				color: #fff;
			}
		}
	}
	.toggle-content {
		margin: 0;
		color: #002060;
		font-weight: 600;
		font-size: 14px;
		height: 100%;
		.ques-container {
			height: 100%;
		}
	}
	form {
		width: 100%;
		max-height: 100px;
		text-align: right;
		textarea {
			min-height: 45px;
			resize: none;
			outline: none;
			border: 2px solid #279aac;
			background: white;
			border-radius: 16px;
			padding: 8px;
			width: 100%;
		}

		.btn {
			font-size: 12px;
			font-weight: 600;
			color: ${COLORS.primary};
			background: transparent;
			cursor: pointer;
			svg {
				margin: 0 4px;
			}
		}
	}
	#refresh-btn {
		border: none;
		background: white;
		padding: 5px 10px;

		&:hover {
			border: solid 1px ${COLORS.primary};
		}
	}
`;

export default function ChatSection({ hallName, currentUser, firebaseDb }) {
	// const [snapshots, loading, error] = useList(firebaseDb)

	const [ showChat, setShowChat ] = useState(false);

	const toggleForm = () => {
		console.log('Submit question');
	};
	// const toggleTab = () => {
	//   setShowChat(!showChat)
	// }

	// const [sending, setSending ] = useState(false)
	// const [userInput, setUserInput] = useState('')
	// const handleInput = (e) => setUserInput(e.target.value)
	// const handleSubmit = (e) => {
	//   e.preventDefault()
	//   setSending(true)
	//   setUserInput('')
	// }

	// const refreshPage = () => {
	//   window.location.reload()
	// }

	// const sendMessage = (text) => {
	//   const payload = {
	//     dbUser: currentUser.fullName,
	//     dbMessage: text,
	//     dbTimestamp: Date.now(),
	//     dbUserId: currentUser.id,
	//   }
	//   if (currentUser.avatar) {
	//     payload.dbUserAvatarUrl = currentUser.avatar.url
	//   }
	//   firebaseDb.push(payload)
	// }
	// const toggleAvatar = () => {
	//   console.log('Your can find this user in Networking Lounge.')
	// }

	return (
		<ChatContainer>
			<div className="divider" />
			<div className="tabs">
				<div className={`${!showChat && 'active'}`} onClick={() => setShowChat(false)}>
					<button>
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
							<polygon points="0 0 24 0 24 24 0 24" />
							<path
								fill="#492777"
								d="M20,2 L4,2 C2.9,2 2,2.9 2,4 L2,22 L6,18 L20,18 C21.1,18 22,17.1 22,16 L22,4 C22,2.9 21.1,2 20,2 Z M19,16 L6,16 L4,18 L4,5 C4,4.45 4.45,4 5,4 L19,4 C19.55,4 20,4.45 20,5 L20,15 C20,15.55 19.55,16 19,16 Z"
							/>
						</svg>
						<small>Ask Question</small>
					</button>
				</div>
			</div>
			<div className="divider" />
			<div className="toggle-content">
				<div className="ques-container">
					<div className="ques-text px-3 py-3">
						Submit your questions anytime. We will answer them during the Q&A session.
					</div>
					<GoogleSheetForm hallName={hallName} toggleForm={toggleForm} />
				</div>
			</div>
		</ChatContainer>
	);
}
