import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import ReactGA from 'react-ga';
import PhoneInput from 'react-phone-input-2';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import config from '../../config';
import SloganImg from '../../images/backdrops/slogan.png';
import Logo from '../../images/logo.png';
import routes from '../../routes';
import { COLORS, SIZES } from '../../styles/theme';

const Page = styled.div`
	min-height: 100vh;
	background-image: url(${config.assets.register.landscape});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: left;
	padding-bottom: 4.5em;
	color: ${COLORS.primary};

	${SIZES.mobile} {
		background-image: url(${config.assets.register.portrait});
		padding: 0;
		padding-bottom: 2.5em;
		font-size: 0.9em;
		color: #ffffff;
	}
	#password {
		width: 12px;
		margin: 2px 4px;
	}
	#main {
		padding-top: 2.5em;
		background-image: radial-gradient(circle at left, rgba(255, 255, 255, 0.9), transparent, transparent);
	}

	.row {
		img {
			width: 80%;
			display: block;
			@media screen and (min-width: 768px) {
				width: 100%;
			}
			@media screen and (min-width: 1025px) {
				width: 80%;
			}
		}
		h6 {
			color: #ffffff;
			text-align: center;
			margin: 16px;
			font-weight: 600;
			${SIZES.mobile} {
				margin: 8px;
			}
		}
		h6.title {
			color: #aef3fd;
			font-weight: 500;
			margin: 0;
			${SIZES.mobile} {
				color: #ffffff;
			}
		}
		#event {
			padding: 2rem 0;
			padding-left: 60px;
			padding-right: 36px;
			align-self: center;
			text-align: center;
			h4 {
				font-weight: 600;
				@media screen and (min-width: 768px) {
					color: #002f62;
					font-size: 23px;
				}
				@media screen and (min-width: 1025px) {
					color: #002f62;
					font-size: auto;
				}
			}
			h4#info {
				color: #279aac;
			}
			p {
				margin: 0;
				strong {
					color: #009a47;
				}
			}
			${SIZES.mobile} {
				text-align: center;
				align-self: flex-start;
				padding: 0;
				margin: 0px auto;
				margin-bottom: 32px;
				h4 {
					margin: 0;
				}
				h4#info {
					color: #ffffff;
					@media screen and (min-width: 768px) {
						color: #279aac;
					}
					@media screen and (min-width: 1025px) {
						color: #279aac;
					}
				}
				p strong {
					color: #aef3fd;
					@media screen and (min-width: 768px) {
						color: #009a47;
					}
					@media screen and (min-width: 1025px) {
						color: #009a47;
					}
				}
			}
		}
		.register-form {
			padding: 2.5em 0;

			.form-group {
				width: 90%;
				margin: 8px auto;
				background: #f8f4ff;
				border: 1px solid #0269ea;
				border-radius: 25px;
				font-weight: 500;
				text-align: center;

				${SIZES.mobile} {
					width: 100%;
					margin: 3px auto;
				}

				label {
					color: ${COLORS.primary};
					padding: 0 8px;
					margin: 0 auto;
					padding-right: 0;
					font-size: 13px;
				}
				input[type='tel'],
				.form-input {
					display: block;
					color: #279aac;
					letter-spacing: 1px;
					width: 90%;
					font-weight: 500;
					background: transparent;
					border: none;
					padding: 5px 18px;
					outline: none;
					text-align-last: center;
					${SIZES.mobile} {
						width: 95%;
						font-size: 12px;
					}
				}
				.react-tel-input {
					color: #279aac;
					input[type='tel'] {
						padding-left: 56px;
						padding-left: 25px;
						box-shadow: none;
					}
					.country-list {
						margin: 0;
					}
					.flag-dropdown {
						background: transparent;
						border: none;
						padding-left: 16px;
						.selected-flag {
							background: transparent;
						}
					}
				}
			}
		}
	}
	.form-check {
		padding-left: 3.25rem;
		${SIZES.mobile} {
			padding-top: 10px;
			display: flex;
			padding-left: 5px;
		}
		.form-check-label {
			color: #ffffff;
			font-weight: 500;
			font-size: 15px;
			margin-left: 8px;
			a {
				margin-left: 3px;
				color: #ffffff;
				text-decoration: underline;
			}
			${SIZES.mobile} {
				font-size: 10px;
			}
		}
	}

	button.submit-btn {
		background: #002060;
		width: 90%;
		color: #aef3fd;
		font-weight: 500;
		font-style: italic;
		border: 1px solid ${COLORS.primary};
		border-radius: 56px;
		display: flex;
		justify-content: space-between;
		padding: 4px 24px;
		margin-right: auto;
		margin-left: auto;
		font-size: 1em;
		outline: none;
		box-shadow: none;

		${SIZES.mobile} {
			width: 90%;
			padding: 4px 24px;
			margin: 10 auto;
		}

		&:disabled {
			cursor: not-allowed;
			background: dimgrey;
			border: solid 1px ${COLORS.primary};
		}

		div {
			line-height: 32px;
		}
		div.arrow {
			line-height: 24px;
			font-size: 1.5em;
			align-self: flex-end;
		}
	}
	#footers {
		#footerL,
		#footerM {
			position: fixed;
			bottom: 0;
			left: 0;
		}
		#footerM {
			display: none;
		}
		${SIZES.mobile} {
			#footerL {
				display: none;
			}
			#footerM {
				display: block;
				width: 100%;
				padding: 16px;
				text-align: center;
				background-color: #0576ba;
			}
		}
	}
	/* searchable select input */
	.select-search {
		width: 100%;
		position: relative;
		box-sizing: border-box;
	}
	.select-search *,
	.select-search *::after,
	.select-search *::before {
		box-sizing: inherit;
	}
	.select-search__value {
		position: relative;
		z-index: 1;
	}
	.select-search__value::after {
		content: '';
		display: inline-block;
		position: absolute;
		top: calc(50% - 9px);
		right: 19px;
		width: 11px;
		height: 11px;
	}
	.select-search__input {
		color: #279aac;
		background: transparent;
		border: none;
		padding: 8px 16px;
		border-radius: 25px;
		display: block;
		width: 100%;
		outline: none;
		text-overflow: ellipsis;
		-webkit-appearance: none;
		cursor: pointer;
	}
	.select-search__input:focus {
		cursor: initial;
	}
	.select-search__options {
		list-style: none;
		padding-right: 8px;
		padding-left: 8px;
	}
	.select-search__row:not(:first-child) {
		border-top: 1px solid #eee;
	}
	.select-search__option,
	.select-search__not-found {
		display: block;
		min-height: 32px;
		width: 100%;
		padding: 0 8px;
		background: #fff;
		border: none;
		outline: none;
		font-size: 14px;
		text-align: left;
		cursor: pointer;
	}
	.select-search__option.is-selected {
		background: ${COLORS.primary};
		color: #ffffff;
	}
	.select-search .select-search__value::after {
		transform: rotate(45deg);
		border-right: 1px solid #000;
		border-bottom: 1px solid #000;
		pointer-events: none;
	}
	.select-search__option.is-highlighted,
	.select-search__option:not(.is-selected):hover {
		background: #f8f4ff;
	}
	.select-search .select-search__select {
		background: #fff;
		box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
		position: absolute;
		z-index: 2;
		bottom: 44px;
		right: 0;
		left: 0;
		border-radius: 3px;
		overflow: auto;
		max-height: 300px;
	}
	.select-search__not-found {
		height: auto;
		padding: 16px;
	}
`;

export default function RegisterFormPage() {
	const history = useHistory();

	const [ loading, setLoading ] = useState(false);
	const [ salutation, setSalutation ] = useState('');
	const [ fullName, setFullName ] = useState('');
	const [ email, setEmail ] = useState('');
	const [ phone, setPhone ] = useState('60');
	const [ department, setDepartment ] = useState('');
	const [ country, setCountry ] = useState('Malaysia');
	const [ password, setPassword ] = useState('');
	const [ agree, setAgree ] = useState(false);
	const optionList = [ 'Mr', 'Ms', 'Miss', 'Mdm', 'Dr', 'Prof' ];
	const countryList = [ 'Malaysia', 'Singapore' ];

	useEffect(() => {
		ReactGA.pageview('/registration/form');
	}, []);

	const handleEmailInput = (e) => {
		setEmail(e.target.value.toLowerCase());
	};
	const handleCheckBox = (e) => {
		setAgree(e.target.checked);
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		setLoading(true);
		const options = {
			salutation,
			full_name: fullName.trim(),
			email,
			phone,
			department: department.trim(),
			country,
			password: password.trim()
		};
		Axios.post(`${config.apiUrl}/api/v1/register`, options)
			.then(() => {
				setLoading(false);
				history.push(routes.postRegister);
			})
			.catch((err) => {
				setLoading(false);
				toast(err.response.data.message);
			});
	};

	const hasEmptyField = !(
		salutation.length &&
		fullName.length &&
		email.length &&
		phone.length >= 7 &&
		department.length &&
		country.length &&
		password.length >= 8 &&
		agree != false
	);

	return (
		<Page>
			<Row id="main" className="px-5 pl-md-0 pt-5 pb-3">
				<Col md={6}>
					<img src={SloganImg} alt="slogan" className="d-none d-md-block d-sm-none" />
					<div className="d-flex h-100 w-100">
						<div id="event" className="mt-md-5">
							<h4 id="title" className="text-left">
								Midyear Conference 2021
							</h4>
							<h4 id="info" className="text-left">
								BI MYSG
							</h4>
							<p className="text-left">
								<strong>5th - 9th July 2021</strong>
							</p>
							<p className="text-left">
								<strong>Commence from 9:00 am</strong>
							</p>
						</div>
					</div>
				</Col>
				<Col md={6}>
					<h6 className="title">Kindly fill in your details below to complete registration</h6>
					<Form onSubmit={handleSubmit} id="signup-form">
						<Row className="register-form py-3" noGutters>
							<Form.Group>
								<label>Salutation</label>
								<select
									className="form-input select-arrow"
									value={salutation}
									onChange={(e) => setSalutation(e.target.value)}
								>
									<option value="" selected hidden>
										-- SELECT --
									</option>
									{optionList.map((item, i) => (
										<option key={`option-${i}`} value={item}>
											{item}
										</option>
									))}
								</select>
							</Form.Group>
							<Form.Group>
								<label>Full Name (as per NRIC or Passport)</label>
								<input
									className="form-input"
									required
									type="text"
									value={fullName}
									onChange={(e) => setFullName(e.target.value)}
								/>
							</Form.Group>
							<Form.Group>
								<label>Email Address</label>
								<input
									className="form-input"
									required
									type="email"
									value={email}
									onChange={handleEmailInput}
								/>
							</Form.Group>
							<Form.Group>
								<label>Mobile Number</label>
								<PhoneInput
									onlyCountries={[ 'my', 'sg' ]}
									inputProps={{ required: true }}
									className="form-input"
									value={phone}
									onChange={(phone) => setPhone(phone)}
									placeholder=""
								/>
							</Form.Group>
							<Form.Group>
								<label>Department</label>
								<input
									className="form-input"
									required
									type="text"
									value={department}
									onChange={(e) => setDepartment(e.target.value)}
								/>
							</Form.Group>
							<Form.Group>
								<label>Country</label>
								<select
									className="form-input"
									value={country}
									onChange={(e) => setCountry(e.target.value)}
								>
									<option value="" selected hidden>
										-- SELECT --
									</option>
									{countryList.map((item, i) => (
										<option key={`option-${i}`} value={item}>
											{item}
										</option>
									))}
								</select>
							</Form.Group>
							<Form.Group>
								<label>
									Password (min 8 characters)
									<svg id="password" viewBox="0 0 48 48">
										<circle fill="#0269EA" cx="24" cy="24" r="21" />
										<rect x="22" y="22" fill="#fff" width="4" height="11" />
										<circle fill="#fff" cx="24" cy="16.5" r="2.5" />
									</svg>
								</label>
								<input
									className="form-input"
									type="password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
							</Form.Group>
							<div className="form-check">
								<input type="checkbox" id="agree-box" onChange={handleCheckBox} />
								<label className="form-check-label">
									I have read and agree to
									<a
										href="https://drive.google.com/file/d/1sItLZmqVq9Gf1gOZLj0tEL2Iw4v4c0Eq/view?usp=sharing"
										target="_blank"
										rel="noopener noreferrer"
									>
										Privacy Data Statement
									</a>
								</label>
							</div>
						</Row>
					</Form>
					<div className="w-100">
						{hasEmptyField && (
							<p className="text-danger text-center">
								<em>
									<small>
										<b>Please fill up all fields</b>
									</small>
								</em>
							</p>
						)}
						<Button
							type="submit"
							className="submit-btn"
							variant="default"
							disabled={hasEmptyField || loading}
							onSubmit={handleSubmit}
							form="signup-form"
						>
							<div>{loading ? 'Loading...' : 'Submit'}</div>
							<div className="arrow">⇀</div>
						</Button>
						<br />
					</div>
				</Col>
			</Row>
			<div id="footers">
				<img src={config.assets.footer.landscape} id="footerL" width="100%" alt="footer" />
				<div id="footerM">
					<img src={Logo} width="25%" className="mx-auto" alt="footer" />
				</div>
			</div>
		</Page>
	);
}
