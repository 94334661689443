import React, { useState } from 'react';
import { useList } from 'react-firebase-hooks/database';
import styled from 'styled-components';
import { RoomDb } from '../constants/firebaseConfig';
import infoIcon from '../images/info.png';
import playIcon from '../images/mobile/play.png';
import pinkPin from '../images/pin.png';
import { COLORS } from '../styles/theme';
import Amplitude from '../utils/amplitudeHelper';
import IframeModal from './GlobalModals/IframeModal';
import { toast } from 'react-toastify';

const PinWrapper = styled.div`
  ${(props) => props.css}
  position: absolute;
  cursor: pointer;
  display: flex;
  justify-content: center;

  &:hover {
    /* box-shadow: 0px 0px 30px 15px ${COLORS.accentLight}; */

    .pins {
      animation-name: bounce;
      animation-duration: 1s;
      animation-fill-mode: both;
      .pin-text {
        display: block;
      }
    }
  }

  .pins {
    margin-top: -55px;
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 60px;
    background-image: ${(props) =>
		props.info ? `url(${infoIcon})` : props.compliance || props.korea ? `url(${playIcon})` : `url(${pinkPin})`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    animation: heartbeat 7s infinite;

    .pin-text {
      position: absolute;
      z-index: 20;
      top: 50%;
      transform: translateY(-50%);
      left: 100%;
      white-space: nowrap;
      display: none;

      h5 {
        color: white;
        text-shadow: 1px 1px 1px #000;
      }
    }
  }
`;

export default function LobbyEntry({
	id,
	showEntryOverlay,
	overlayText,
	setOverlayTitle,
	pinText,
	destination,
	css,
	showHelpDesk
}) {
	const [ koreaVideoOpen, setKoreaVideoOpen ] = useState(false);
	const [ complianceVideoOpen, setComplianceVideoOpen ] = useState(false);
	const [ snapshots ] = useList(RoomDb);

	const handleClick = () => {
		if (id === 'Compliance') {
			Amplitude.clickLobbyButton('Compliance Video');
			setComplianceVideoOpen(true);
		} else if (id === 'Korea') {
			Amplitude.clickLobbyButton('South Korean Video');
			setKoreaVideoOpen(true);
		} else if (id === 'Quiz') {
			Amplitude.clickLobbyButton('PV Quiz');
			window.open('https://www.flexiquiz.com/SC/N/pvquiz');
		} else if (id === 'auditorium') {
			toast('Event has ended.');
			// Amplitude.clickLobbyButton('Plenary Hall');
			// let entries = snapshots.filter((item) => {
			// 	if (item.val().name === 'HP PLENARY' || item.val().name === 'AH PLENARY') {
			// 		return item;
			// 	}
			// });
			// showEntryOverlay(entries);
			// if (overlayText) {
			// 	setOverlayTitle(overlayText);
			// }
		} else if (id === 'exhibition') {
			toast('Event has ended.');
			// Amplitude.clickLobbyButton('Breakout Session');
			// let entries = snapshots.filter((item) => {
			// 	if (item.val().name !== 'HP PLENARY' && item.val().name !== 'AH PLENARY') {
			// 		return item;
			// 	}
			// });
			// showEntryOverlay(entries);
			// if (overlayText) {
			// 	setOverlayTitle(overlayText);
			// }
		} else if (id === 'helpdesk') {
			showHelpDesk();
		}
	};

	return (
		<div>
			<PinWrapper
				css={css}
				info={id === 'helpdesk'}
				compliance={id === 'Compliance'}
				korea={id === 'Korea'}
				onClick={handleClick}
			>
				<div className="pins">
					<div className="pin-text">
						<h5>{pinText}</h5>
					</div>
				</div>
			</PinWrapper>
			{complianceVideoOpen && (
				<IframeModal compliance iframeOpen={complianceVideoOpen} setIframeOpen={setComplianceVideoOpen} />
			)}
			{koreaVideoOpen && <IframeModal iframeOpen={koreaVideoOpen} setIframeOpen={setKoreaVideoOpen} />}
		</div>
	);
}
