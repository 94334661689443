import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import config from '../config';

export default function BackgroundAudio() {
	const audioRef = useRef(null);
	const { muted } = useSelector((state) => state.layout);

	useEffect(() => {
		audioRef.current.volume = config.VOLUME_VALUE;
	}, []);

	return (
		<audio
			loop
			autoPlay
			muted={muted}
			style={{ visibility: 'hidden' }}
			src={config.assets.lobbyMusic}
			ref={audioRef}
		/>
	);
}
