import React from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { SIZES } from '../styles/theme';
import config from '../config';
import LoginPage from '../pages/LoginPage';
import WelcomePage from '../pages/WelcomePage';
import routes from '../routes';

const Page = styled.div`
	background: url(${config.assets.countdown.landscape}) no-repeat center center fixed;
	background-size: cover;
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	${SIZES.mobile} {
		background: url(${config.assets.countdown.portrait}) no-repeat top;
		background-size: 100% 100%;
		min-height: 100%;
		width: 100vw;
		overflow: hidden;
		position: absolute;
		display: block;
		margin: 0 auto;

		@media screen and (min-width: 540px) {
			background: url(${config.assets.countdown.ipad});
			background-size: cover;
		}
	}
`;

export default function EntrancePages({ children }) {
	return (
		<Page>
			<Switch>
				<Route exact path={routes.root} component={WelcomePage} />
				<Route path={routes.login} component={LoginPage} />
			</Switch>
		</Page>
	);
}
