import amplitude from 'amplitude-js';
import config from '../config';

const Amplitude = {
	init: () => {
		amplitude.getInstance().init(config.API_KEYS.amplitude);
	},

	// TODO: Put all amplitude tracking functions here
	visit: (page) => {
		amplitude.getInstance().logEvent(`Visit ${page}`);
	},

	clickLobbyButton: (type) => {
		amplitude.getInstance().logEvent('Click Lobby Button', {
			buttonType: type
		});
	},

	clickPlenary: (type) => {
		amplitude.getInstance().logEvent('Click Plenary', { buttonType: type });
	},

	clickBreakOutSession: (type) => {
		amplitude.getInstance().logEvent('Click Breakout Session', { buttonType: type });
	},

	clickQAndAButton: () => {
		amplitude.getInstance().logEvent('Click Q&A Button');
	},

	submitQuestion: (details) => {
		amplitude.getInstance().logEvent('Submit Gsheets Question', {
			UserID: details.id,
			FullName: details.fullName,
			Email: details.email,
			Question: details.question,
			HallName: details.hallName
		});
	},

	clickLobby: () => {
		amplitude.getInstance().logEvent('Click Lobby');
	},

	clickAgenda: () => {
		amplitude.getInstance().logEvent('Click Agenda');
	},
	clickNotification: () => {
		amplitude.getInstance().logEvent('Click Notifications');
	},
	clickMute: () => {
		amplitude.getInstance().logEvent('Click Mute button');
	},

	clickLogout: () => {
		amplitude.getInstance().logEvent('Click Logout');
	},

	clickHelpDesk: () => {
		amplitude.getInstance().logEvent('Click Helpdesk');
	},

	visitExhibition: (hallNumber) => {
		amplitude.getInstance().logEvent(`Visit Exhibition Hall`, {
			hallNumber: hallNumber
		});
	},

	visitBooth: (boothName) => {
		amplitude.getInstance().logEvent(`Visit Booth`, {
			boothName: boothName
		});
	},

	clickZoomButton: (type) => {
		amplitude.getInstance().logEvent('Click Zoom Conference', {
			buttonType: type
		});
	},

	clickMenu: () => {
		amplitude.getInstance().logEvent('Click Menu');
	},

	clickFaculty: () => {
		amplitude.getInstance().logEvent('Click Faculty Modal');
	},

	clickCheckIn: () => {
		amplitude.getInstance().logEvent('Click Checkin List');
	},

	clickHotButtons: (boothName, type) => {
		amplitude.getInstance().logEvent('Click Booth HotButton', {
			boothName: boothName,
			hotButtonType: type
		});
	},

	uploadImage: () => {
		amplitude.getInstance().logEvent('Upload profile image');
	},

	clickPodcast: (title) => {
		amplitude.getInstance().logEvent(`Click podcast: ${title}`);
	},

	dropNamecard: (details) => {
		amplitude.getInstance().logEvent('Drop name card', {
			SenderID: details.senderId,
			SenderFullName: details.senderFullName,
			SenderEmail: details.senderEmail,
			ReceiverFullName: details.receiverFullName,
			ReceiverEmail: details.receiverEmail
		});
	}
};

export default Amplitude;
