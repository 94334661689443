import { firebaseDb, getUserCountDb, getOnlineStatusDb } from '../constants/firebaseConfig';
import { useEffect } from 'react';
import { useLocation } from 'react-router';

const usePageUserCount = (currentUser) => {
	const location = useLocation();
	const pagePath = location.pathname;

	useEffect(
		() => {
			if (currentUser && currentUser.id) {
				getUserCountDb(currentUser.id, pagePath).set(new Date().toString());
				getOnlineStatusDb(currentUser.id).set(true);
			}

			return () => {
				if (currentUser && currentUser.id) {
					getUserCountDb(currentUser.id, pagePath).remove();
					getOnlineStatusDb(currentUser.id).set(false);
				}
			};
		},
		[ pagePath, currentUser.id ]
	);

	firebaseDb.ref('.info/connected').on('value', (snapshot) => {
		if (currentUser && currentUser.id) {
			if (!snapshot.val()) {
				getOnlineStatusDb(currentUser.id).set(false);
			}
			if (snapshot.val()) {
				getOnlineStatusDb(currentUser.id).set(true);
			}
			getUserCountDb(currentUser.id, pagePath).onDisconnect().remove();
			getOnlineStatusDb(currentUser.id).onDisconnect().set(false);
		}
	});
};

export { usePageUserCount };
