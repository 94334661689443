import React, { useEffect } from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga';
import { COLORS, SIZES } from '../../styles/theme';
import config from '../../config';
import BgImg from '../../images/backdrops/post-registration.jpg';
import BgImgM from '../../images/backdrops/post-registrationM.png';
import SloganImg from '../../images/backdrops/slogan.png';
import Logo from '../../images/logo.png';

const Page = styled.div`
	min-height: 100vh;
	background-image: url(${BgImg});
	background-repeat: no-repeat;
	background-position: top;
	background-size: cover;
	color: ${COLORS.primary};

	${SIZES.mobile} {
		font-size: 0.8em;
		background-image: url(${BgImgM});
	}
	#left {
		padding: 5rem 0;
		padding-bottom: 0;
		width: 40%;
		#message {
			padding: 2rem 0;
			padding-left: 96px;
			h5 {
				font-weight: 600;
			}
		}
		#event {
			padding: 2rem 0;
			padding-left: 96px;
			h4 {
				font-weight: 700;
			}
			h4#info {
				color: #279aac;
			}
			p {
				margin: 0;
				strong {
					color: #009a47;
				}
			}
		}
		${SIZES.mobile} {
			width: 100%;
			height: 100vh;
			background-image: radial-gradient(circle at left, #ffffff, transparent);

			#header img {
				width: 95%;
			}
			#message,
			#event {
				padding-left: 2rem;
				padding-right: 2rem;
				h5 {
					font-size: 1.5em;
				}
				strong {
					font-size: 1.2em;
				}
				@media screen and (min-width: 1024px) {
					h5 {
						font-weight: 700;
						font-size: 2.5em;
					}
					h4 {
						font-weight: 700;
						font-size: 2.2em;
					}
					strong {
						font-size: 1.8em;
					}
				}
			}
		}
	}
	#footers {
		#footerL,
		#footerM {
			position: absolute;
			bottom: 0;
			left: 0;
		}
		#footerM {
			display: none;
		}
		${SIZES.mobile} {
			#footerL {
				display: none;
			}
			#footerM {
				display: block;
				width: 100%;
				padding: 16px;
				text-align: center;
				background-color: #0576ba;
			}
		}
	}
`;

export default function PostRegisterPage() {
	useEffect(() => {
		ReactGA.pageview('/registration/complete');
	}, []);

	return (
		<Page>
			<div id="left">
				<div id="header">
					<img src={SloganImg} width="100%" alt="slogan" />
				</div>
				<div id="message">
					<h5>Thank You!</h5>
					<h5>A confirmation email will be sent to you within 2-3 working days.</h5>
				</div>
				<div id="event">
					<h4 id="title" className="text-left">
						<b>Midyear Conference 2021</b>
					</h4>
					<h4 id="info" className="text-left">
						<b>BI MYSG</b>
					</h4>
					<p className="text-left">
						<strong>5th - 9th July 2021</strong>
					</p>
					<p className="text-left">
						<strong>Commence from 9:00 am</strong>
					</p>
				</div>
			</div>
			<div id="footers">
				<img src={config.assets.footer.landscape} id="footerL" width="100%" alt="footer" />
				<div id="footerM">
					<img src={Logo} width="35%" className="mx-auto" alt="footer" />
				</div>
			</div>
		</Page>
	);
}
