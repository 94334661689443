export default {
  root: '/',

  register: '/registration',
  postRegister: '/registration/complete',
  forgotPassword: '/registration/forgot-password',
  resetPassword: '/registration/create-password',
  login: '/login',

  notifications: '/notifications',
  menu: '/menu',
  help: '/help',

  lobby: '/lobby',

  // networking: '/networking-lounge',
  // cloudflix: '/highlights',

  auditorium1: '/hp-plenary-auditorium',
  auditorium2: '/ah-plenary-auditorium',

  exhibition1: '/breakout-session',
  quiz: '/quiz',
  korea: '/mobile-video-2',
  compliance: '/mobile-video-1',
  zoom: '/zoom',

  // gallerium: '/gallerium',
}
