import React, { useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import LoadIcon2 from '../../components/LoadIcon2.js';
import Angels from '../../images/event/Angels.jpg';
import Animal_Health from '../../images/event/Animal-Health.jpg';
import Metabolic from '../../images/event/Metabolic.jpg';
import Plenary from '../../images/event/Plenary.jpg';
import Pradaxa from '../../images/event/Pradaxa.jpg';
import Respiratory from '../../images/event/Respiratory.jpg';
import SpecialtyBusinessUnit from '../../images/event/Specialty-Business-Unit.jpg';
import Twynsta from '../../images/event/Twynsta.jpg';
import arrow from '../../images/navigation/up.png';
import { COLORS } from '../../styles/theme';

const StyledModal = styled(Modal)`
  text-align: center;
  color: ${COLORS.primary};

  .modal-dialog {
    min-width: 75%;
  }
  .modal-content {
    background-color: rgba(255, 255, 255, 0.9);
    border: none;
    border-radius: 0;
    padding: 24px 36px;
    margin-bottom: 24px;

    .modal-header {
      border: none;
      text-align: left;
      .modal-title {
        font-size: 30px;
        font-weight: bold;
        font-style: italic;
      }
    }
    .divider {
      background: ${COLORS.primary};
      height: 4.5px;
      margin: 32px 0;
    }
    .agenda {
      button {
        margin: 16px;
        background: white;
        border: 2px solid ${COLORS.primary};
        font-weight: 600;
        border-radius: 10px;
        padding: 4px 12px;
        color:  #002060;
        font-size: 20px;
        width:20%;
      }
      .active {
        background:#002060;
        color:  white;
      }
    }
    .agenda-image {
      p {
        font-weight: 600;
        font-size: 1.5em;
      }
    }
  }
  .back-top {
    background: transparent;
    border: none;
    position:absolute;
    bottom: 0;
    right: 0;
  }
  .modal-body {
    padding-bottom: 36px;
  }
`;

export default function ProgrammeModal({ showModal, hideSchedule }) {
	const startModal = useRef();
	const [ loading, setLoading ] = useState(true);

	const [ selectedAgenda, setSelectedAgenda ] = useState({ id: 1, name: 'Plenary', image: Plenary });
	const agendaList = [
		{ id: 1, name: 'Plenary Session', image: Plenary },
		{ id: 2, name: 'Angels', image: Angels },
		{ id: 3, name: 'Animal Health', image: Animal_Health },
		{ id: 4, name: 'Metabolic', image: Metabolic },
		{ id: 5, name: 'Pradaxa', image: Pradaxa },
		{ id: 6, name: 'Respiratory', image: Respiratory },
		{ id: 7, name: 'Specialty Business Unit', image: SpecialtyBusinessUnit },
		{ id: 8, name: 'Twynsta', image: Twynsta }
	];

	const scrollTop = (ref) => {
		ref.current.scrollIntoView({ behavior: 'smooth' });
	};

	return (
		<StyledModal show={showModal} onHide={hideSchedule} size="xl" aria-labelledby="menu-modal" centered>
			<Modal.Header closeButton>
				<Modal.Title>AGENDA</Modal.Title>
				<span ref={startModal} />
			</Modal.Header>
			<div className="divider" />
			<Modal.Body>
				<div className="agenda">
					{agendaList.map((agenda) => (
						<button
							key={`agenda-${agenda.id}`}
							className={`${selectedAgenda.id === agenda.id && 'active'}`}
							onClick={() => {
								setSelectedAgenda(agenda);
								setLoading(true);
							}}
							style={agenda.id === 7 ? { fontSize: '15px', padding: '10px 9px' } : { fontSize: '20px' }}
						>
							{agenda.name}
						</button>
					))}
				</div>

				<div className="divider" />
				<div className="agenda-image">
					<p>{selectedAgenda.name}</p>
					{loading && <LoadIcon2 style={{ top: '100%' }} />}
					<img src={selectedAgenda.image} onLoad={() => setLoading(false)} alt="agenda" width="100%" />
				</div>
				<button onClick={() => scrollTop(startModal)} className="back-top">
					<img src={arrow} width="75" alt="up-arrow" />
				</button>
			</Modal.Body>
		</StyledModal>
	);
}
