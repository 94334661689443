import firebase from 'firebase/app';
import 'firebase/database';
require('firebase/auth');

const firebaseConfig = {
	apiKey: 'AIzaSyDZtBtK6ztPNBJd85ONlnRIjLdGuLZ7O84',
	authDomain: 'bimysg-2021.firebaseapp.com',
	databaseURL: 'https://bimysg-2021-default-rtdb.firebaseio.com',
	projectId: 'bimysg-2021',
	storageBucket: 'bimysg-2021.appspot.com',
	messagingSenderId: '694877476662',
	appId: '1:694877476662:web:e079f03f42371b88ea4597',
	measurementId: 'G-2Q1W8J6KP0'
};

firebase.initializeApp(firebaseConfig);

const firebaseDb = firebase.database();
const firebaseAuth = firebase.auth();

const getDbRef = (dbRef) => {
	const envDbRef = `${process.env.REACT_APP_STAGE === 'production' ? 'production' : 'local'}/${dbRef}`;
	return firebaseDb.ref(envDbRef);
};

const ChatDb = getDbRef('chat');
const ChatKfairDb = getDbRef('chatKfair');
const ChatMcDb = getDbRef('chatMc');
const NoticeDb = getDbRef('notice');
const RoomDb = getDbRef('room');
const NotificationDb = getDbRef('pushNotifications');

const getUniqueSessionDb = (uid) => getDbRef(`uniqueSession/${uid}`);
const getUserCountDb = (uid, path) => getDbRef(`userCount/${path}/${uid}`);
const getUserSessionDb = (uid) => getDbRef(`userSessions/${uid}`);
const getOnlineStatusDb = (uid) => getDbRef(`onlineStatus/${uid}`);

export {
	firebaseDb,
	firebaseAuth,
	ChatDb,
	ChatKfairDb,
	ChatMcDb,
	NoticeDb,
	NotificationDb,
	RoomDb,
	getUniqueSessionDb,
	getUserCountDb,
	getUserSessionDb,
	getOnlineStatusDb
};
