import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import LoadIcon from '../../components/LoadIcon';
import { muteMusic, unmuteMusic } from '../../ducks/layout';
import routes from '../../routes';
import Amplitude from '../../utils/amplitudeHelper';

const Page = styled.div`
	background: white;
	height: calc(100% - 60px);
	width: 100vw;
	overflow-y: scroll;
	border-radius: 0px 0px 25px 25px;
	position: absolute;

	.outer-wrapper {
		width: 100%;
	}

	.container {
		width: 90%;
		margin: auto;
	}

	.title-container {
		margin: 20px 0px 10px 0px;
		height: 50px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.title {
		width: 70%;
		font-size: 22px;
		font-weight: bold;
		font-style: italic;
		color: black;
		margin: 0;
	}

	.back-button {
		font-size: 12px;
		color: #312384;
		font-weight: bold;
	}
	.iframe-container {
		position: relative;
		padding-bottom: 56.25%;
		height: 0;
		overflow: hidden;
		iframe {
			position: absolute;
			top: 0;
			left: 0;
		}
	}
	.quiz-button {
		background: black;
		border-radius: 0px;
		color: white;
	}
`;

const MobileKoreaVideo = () => {
	const [ loading, setLoading ] = useState(true);
	const dispatch = useDispatch();
	useEffect(
		() => {
			dispatch(muteMusic());

			return () => {
				if (localStorage.getItem('muted') === 'false') {
					dispatch(unmuteMusic());
				}
			};
		},
		[ dispatch ]
	);

	const onClickQuiz = () => {
		Amplitude.clickLobbyButton('Interactive-Quiz');
		window.open('https://www.flexiquiz.com/SC/N/hr-interactive-activities', '_blank');
	};

	return (
		<Page>
			<div className="container">
				<div className="title-container">
					<p className="title">Feel the Inclusive behaviors in Korea</p>
					<Link className="back-button" to={routes.lobby}>
						Back
					</Link>
				</div>
				<div className="iframe-container">
					{loading && <LoadIcon />}
					<iframe
						title="BI MYSG Lobby Video"
						width="100%"
						height="100%"
						src="https://player.vimeo.com/video/568311178?autoplay=1&amp"
						allow="autoplay; fullscreen; encrypted-media"
						allowFullScreen
						allowtransparency="true"
						frameBorder="0"
						onLoad={() => {
							setLoading(false);
						}}
					/>
				</div>
				<div onClick={onClickQuiz} className="quiz-button">
					<b>Click here to participate our quiz</b>
				</div>
			</div>
		</Page>
	);
};

export default MobileKoreaVideo;
