import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { muteMusic, unmuteMusic } from '../../ducks/layout';
import Amplitude from '../../utils/amplitudeHelper';
import LoadIcon from '../LoadIcon';

const StyledModal = styled(Modal)`
  .modal-content {
   background-color:black;
    border: none;
  }
  .iframe-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .quiz-button {
    background:white;
    border-radius: 0px;
    color: black;
		text-align:center;
    font-size: 20px;
		cursor: pointer;
  }
`;

export default function IframeModal({ iframeOpen, setIframeOpen, iframe, compliance }) {
	const [ loading, setLoading ] = useState(true);
	const dispatch = useDispatch();
	useEffect(
		() => {
			iframeOpen ? dispatch(muteMusic()) : dispatch(unmuteMusic());

			return () => {
				if (localStorage.getItem('muted') === 'false') {
					dispatch(unmuteMusic());
				}
			};
		},
		[ dispatch, iframeOpen ]
	);

	const onClickQuiz = () => {
		Amplitude.clickLobbyButton('Interactive-Quiz');
		window.open('https://www.flexiquiz.com/SC/N/hr-interactive-activities', '_blank');
	};

	return (
		<StyledModal
			show={iframeOpen}
			onHide={() => setIframeOpen(false)}
			size="lg"
			aria-labelledby="podcast-modal"
			centered
		>
			<Modal.Body className="p-0">
				<div className="iframe-container">
					{loading && <LoadIcon />}
					<iframe
						title="BI MYSG Lobby Video"
						width="100%"
						height="100%"
						src={
							compliance ? (
								'https://player.vimeo.com/video/568311063?autoplay=1&amp'
							) : (
								'https://player.vimeo.com/video/568311178?autoplay=1&amp'
							)
						}
						allow="autoplay; fullscreen; encrypted-media"
						allowFullScreen
						allowtransparency="true"
						frameBorder="0"
						onLoad={() => {
							setLoading(false);
						}}
					/>
				</div>
				{!compliance && (
					<div onClick={onClickQuiz} className="quiz-button">
						<b>Click here to participate our quiz</b>
					</div>
				)}
			</Modal.Body>
		</StyledModal>
	);
}
