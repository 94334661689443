import React, { useEffect } from 'react';
import { useList } from 'react-firebase-hooks/database';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { RoomDb } from '../../constants/firebaseConfig';
import { logoutUser } from '../../ducks/auth';
import { toggleMute } from '../../ducks/layout';
import SloganImg from '../../images/backdrops/slogan.png';
import expo from '../../images/mobile/expo.png';
import meeting from '../../images/mobile/meeting.png';
import BgImgM from '../../images/mobile/mobile-lobby.jpg';
import mute from '../../images/mobile/mute.png';
import quiz from '../../images/mobile/quiz.png';
import zoom from '../../images/mobile/zoom.png';
import routes from '../../routes';
import { COLORS } from '../../styles/theme';
import Amplitude from '../../utils/amplitudeHelper';
import MobileNotice from './MobileNotice';

const MobileLobby = ({ currentUser }) => {
	const [ snapshots, loading, error ] = useList(RoomDb);

	const dispatch = useDispatch();
	const { muted } = useSelector((state) => state.layout);

	const history = useHistory();

	useEffect(
		() => {
			Amplitude.visit('Lobby');
		},
		[ currentUser.id ]
	);

	const toggleAudio = () => {
		Amplitude.clickMute();
		dispatch(toggleMute());
	};

	const handleLogout = () => {
		Amplitude.clickLogout();
		dispatch(logoutUser());
	};

	const enterHP = () => {
		let hall = snapshots.filter((item) => {
			if (item.val().name === 'HP PLENARY') {
				return item;
			}
		});
		if (hall[0].val().active || currentUser.role === 'superadmin') {
			Amplitude.clickPlenary('HP Plenary');
			history.push(routes.auditorium1);
		} else {
			toast('Event has ended.');
		}
	};
	const enterAH = () => {
		let hall = snapshots.filter((item) => {
			if (item.val().name === 'AH PLENARY') {
				return item;
			}
		});
		if (hall[0].val().active || currentUser.role === 'superadmin') {
			Amplitude.clickPlenary('AH Plenary (Zoom)');
			window.open(hall[0].val().url, '_blank');
		} else {
			toast('Event has ended.');
		}
	};

	const enterBreakoutSession = () => {
		Amplitude.clickLobbyButton('Breakout Session');
		// history.push(routes.exhibition1);
		toast('Event has ended.');
	};

	const enterComplianceVideo = () => {
		Amplitude.clickLobbyButton('Compliance Video');
		history.push(routes.compliance);
	};

	const enterQuiz = () => {
		Amplitude.clickLobbyButton('PV Quiz');
		window.open('https://www.flexiquiz.com/SC/N/pvquiz', '_blank');
	};

	const enterKoreaVideo = () => {
		Amplitude.clickLobbyButton('South Korean Video');
		history.push(routes.korea);
	};

	if (loading || error) {
		return <div />;
	}
	return (
		<div>
			<Page>
				<img className="banner" src={SloganImg} alt="banner" />
				<MobileNotice />
				<div className="menu">
					<p className="title">Virtual Halls</p>
					<div className="btn-container">
						<div className="mute-button" onClick={toggleAudio}>
							<img className="mute-icon" src={mute} alt="mute" />
							<p className="mute-label">{muted ? 'Unmute' : 'Mute'}</p>
						</div>
						<div className="logout-button" onClick={handleLogout}>
							<svg className="logout-icon" width="30.797" height="29.609" viewBox="0 0 30.797 29.609">
								<g transform="translate(0 0)">
									<g>
										<g>
											<path
												d="M311.743,156.064c.012-.014.022-.029.033-.044s.027-.035.039-.053.022-.036.033-.054.02-.033.029-.05.018-.037.027-.056.017-.035.025-.053.014-.037.02-.055.014-.039.021-.059.01-.037.014-.056.011-.04.015-.061.007-.044.01-.065.006-.036.008-.054a1.253,1.253,0,0,0,0-.243c0-.018-.005-.036-.008-.054s-.006-.044-.01-.065-.01-.041-.015-.061-.009-.038-.014-.056-.013-.039-.02-.059-.013-.037-.02-.055-.016-.036-.025-.053-.017-.038-.027-.056-.02-.033-.029-.05-.021-.036-.033-.054-.026-.035-.039-.053-.021-.03-.033-.044c-.026-.031-.053-.061-.081-.09h0l-3.7-3.7a1.234,1.234,0,1,0-1.745,1.745l1.6,1.6H300.92a1.234,1.234,0,1,0,0,2.467h6.891l-1.6,1.6a1.234,1.234,0,0,0,1.745,1.745l3.7-3.7h0Q311.7,156.111,311.743,156.064Z"
												transform="translate(-281.225 -141.712)"
												fill="#002060"
											/>
											<path
												d="M21.984,18.277a1.234,1.234,0,0,0-1.234,1.234v6.168H15.816V5.941h0a1.234,1.234,0,0,0-.879-1.182L10.651,3.473h10.1V9.642a1.234,1.234,0,0,0,2.467,0V2.24a1.234,1.234,0,0,0-1.234-1.234H2.245c-.036,0-.073,0-.108,0a1.182,1.182,0,0,0-1.069.859c-.009.028-.016.056-.023.084l0,.009q-.01.044-.017.089l0,.013c0,.028-.007.056-.009.084a1.32,1.32,0,0,0,0,.137V26.914A1.234,1.234,0,0,0,2,28.123L14.34,30.591a1.234,1.234,0,0,0,1.476-1.21V28.147h6.168a1.234,1.234,0,0,0,1.234-1.234v-7.4A1.234,1.234,0,0,0,21.984,18.277Zm-8.636,9.6L3.479,25.9V3.9l9.869,2.961V27.876Z"
												transform="translate(-1.011 -1.006)"
												fill="#002060"
											/>
										</g>
									</g>
								</g>
							</svg>
							<p className="logout-label">Logout</p>
						</div>
					</div>
					<div className="list-wrapper">
						<div className="item-box">
							<div className="icon-wrapper">
								<img className="expo" src={meeting} alt="meeting" />
							</div>
							<p className="item-label">HP Plenary</p>
							<div onClick={enterHP} className="item-button">
								Enter
							</div>
						</div>

						<div className="item-box">
							<div className="icon-wrapper">
								<img className="expo" src={meeting} alt="meeting" />
							</div>
							<p className="item-label">AH Plenary</p>
							<div onClick={enterAH} className="item-button">
								Enter
							</div>
						</div>

						<div className="item-box">
							<div className="icon-wrapper">
								<img className="expo" src={expo} alt="expo" />
							</div>
							<p className="item-label">Breakout Session</p>
							<div onClick={enterBreakoutSession} className="item-button">
								Enter
							</div>
						</div>

						<div className="item-box">
							<div className="icon-wrapper">
								<img className="expo" src={zoom} alt="zoom" />
							</div>
							<p className="item-label">Compliance</p>
							<div onClick={enterComplianceVideo} className="item-button">
								Enter
							</div>
						</div>

						<div className="item-box">
							<div className="icon-wrapper">
								<img className="expo" src={quiz} alt="quiz" />
							</div>
							<p className="item-label">PV Quiz</p>
							<div onClick={enterQuiz} className="item-button">
								Enter
							</div>
						</div>

						<div className="item-box">
							<div className="icon-wrapper">
								<img className="expo" src={zoom} alt="zoom" />
							</div>
							<p className="item-label" style={{ fontSize: '9px' }}>
								Feel the Inclusive behaviors in Korea
							</p>
							<div onClick={enterKoreaVideo} className="item-button">
								Enter
							</div>
						</div>
					</div>
				</div>
			</Page>
		</div>
	);
};

const Page = styled.div`
	background: url(${BgImgM});
	background-size: 130%;
	background-position: 100% 0%;
	background-repeat: no-repeat;

	height: calc(100% - 60px);
	width: 100vw;
	overflow: hidden;
	border-radius: 0px 0px 25px 25px;
	position: absolute;

	.banner {
		width: 80%;
		position: absolute;
		top: 30%;
		left: 0%;
	}

	.logo {
		width: 50%;
		position: absolute;
		top: calc(35% - 120px);
		left: 50%;
		transform: translateX(-50%);
	}

	.menu {
		width: 100%;
		height: 250px;
		position: absolute;
		bottom: 0%;
		left: 50%;
		transform: translateX(-50%);
		display: inline-block;
		background: white;
		border-radius: 20px 20px 0px 0px;
	}

	.title {
		font-size: 20px;
		font-weight: bold;
		font-style: italic;
		position: absolute;
		top: 17px;
		left: 20px;
	}

	.btn-container {
		display: flex;
		position: absolute;
		top: 20px;
		right: 20px;
	}

	.mute-button,
	.logout-button {
		width: 80px;
		height: 22px;
		display: flex;
		align-items: center;
	}
	.mute-icon {
		height: 15px;
		margin-right: 3px;
	}
	.logout-icon {
		height: 15px;
	}

	.mute-label,
	.logout-label {
		font-size: 12px;
		margin: 0;
	}
	.logout-label {
		color: #002060;
	}

	.list-wrapper {
		width: 100%;
		height: 180px;
		position: absolute;
		top: 50px;
		left: 0;
		overflow-x: scroll;
		display: flex;
		align-items: center;
	}

	.item-box {
		width: 130px;
		height: 160px;
		margin-left: 20px;
		border: solid 3px #279aac;
		border-radius: 10px;
		background: white;
		flex-shrink: 0;
		box-sizing: border-box;
		padding: 10px 15px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
	}

	.icon-wrapper {
		width: 60px;
		height: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.item-label {
		font-size: 11px;
		font-weight: bold;
		text-align: center;
		color: #272727;
		margin: 0px;
	}

	.item-button {
		background-color: ${COLORS.primary};
		color: white;
		border: none;
		border-radius: 30px;
		font-size: 12px;
		font-weight: bold;
		width: 90px;
		height: 25px;
		margin: 0px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.meeting1 {
		width: 135%;
		transform: translateY(15%);
	}

	.meeting2 {
		width: 85%;
	}

	.meeting {
		width: 150%;
	}

	.expo {
		width: 170%;
	}

	.gallerium {
		width: 140%;
	}

	.networking {
		width: 110%;
	}

	@media screen and (min-width: 540px) {
		background-size: 100%;
		background-position: 100% 0%;

		.banner {
			width: 65%;
			top: 30%;
		}

		.logo {
			width: 30%;
			position: absolute;
			top: calc(35% - 180px);
			left: 50%;
			transform: translateX(-50%);
		}
	}
	@media screen and (min-width: 768px) {
		/* .banner {
      width: 45%;
    } */
		.menu {
			height: 280px;
			.list-wrapper {
				margin-top: 30px;
			}
		}
	}
	@media screen and (min-width: 810px) {
		.banner {
			width: 45%;
		}
		.menu {
			height: 275px;
			.list-wrapper {
				margin-top: 30px;
			}
		}
	}
`;

export default MobileLobby;
