import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import routes from '../routes';
import { COLORS } from '../styles/theme';
import Amplitude from '../utils/amplitudeHelper';

const dialogOverlayId = 'lobby-overlay';

const Overlay = styled.div`
	z-index: 2000; /* bootstrap nav has 1030 */
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	display: flex;
	justify-content: center;
	align-items: center;

	.box-dialog {
		border-radius: 3px;
		width: 90%;
		max-height: 80vh;
		max-width: 850px;
		background-color: rgba(255, 255, 255, 0.9);
		text-align: left;
		padding: 10px 20px 10px 0px;

		display: flex;
		justify-content: center;
		align-items: flex-start;
		align-content: center;
		flex-wrap: wrap;
		flex-direction: column;

		p {
			padding-left: 1em;
			font-weight: 700;
			font-size: 1.2em;
			color: #002060;
		}

		a {
			display: block;
			flex-direction: column;
			font-size: 1.5em;
			font-weight: bold;
			text-transform: uppercase;
			letter-spacing: 1px;
			color: ${COLORS.cyan};
			text-decoration: none;
			white-space: nowrap;
			margin-top: 5px;
			small {
				font-size: 10px;
			}
			span {
				margin-right: 8px;
				visibility: hidden;
			}
			p {
				color: ${COLORS.cyan};
			}

			&:hover {
				color: ${COLORS.blue};
				span {
					visibility: visible;
				}
			}
		}
	}
`;

export default function LobbyOverlay({ overlay, overlayTitle, hideOverlay, currentUser }) {
	const history = useHistory();
	if (!(overlay && Array.isArray(overlay))) return null;

	const checkActive = (item) => {
		if (item.val().active || currentUser.role === 'superadmin') {
			Amplitude.clickPlenary('AH Plenary (Zoom)');
			window.open(item.val().url, '_blank');
		} else {
			toast('The Plenary Hall is now closed.');
		}
	};

	const checkHPPlenary = (active) => {
		if (active || currentUser.role === 'superadmin') {
			Amplitude.clickPlenary('HP Plenary');
			history.push(routes.auditorium1);
		} else {
			toast('The Plenary Hall is now closed.');
		}
	};

	const handleOpen = (item) => {
		if (item.val().active || currentUser.role === 'superadmin') {
			console.log('ehhl');
			Amplitude.clickBreakOutSession(item.val().name);
			window.open(item.val().url, '_blank');
		} else {
			toast('Event has ended.');
		}
	};
	return (
		<Overlay
			onClick={(e) => {
				if (e.target.id === dialogOverlayId) {
					hideOverlay();
				}
			}}
			id={dialogOverlayId}
		>
			<div className="box-dialog">
				<p>{overlayTitle}</p>
				{overlay.map((entry) => {
					// for AH auditorium, only open on 19th 8am-1pm
					let { name, url, active } = entry.val();
					if (name === 'HP PLENARY') {
						return (
							<Link key={name} onClick={() => checkHPPlenary(active)}>
								<span>→</span>
								{name}
								{active && <small className="badge badge-pill badge-danger ml-2">LIVE</small>}
							</Link>
						);
					}
					if (name === 'AH PLENARY') {
						return (
							<Link key={name} onClick={() => checkActive(entry)}>
								<span>→</span>
								{name}
								{active && <small className="badge badge-pill badge-danger ml-2">LIVE</small>}
							</Link>
						);
					} else {
						// for breakout session
						return (
							<Link key={name} href={url} onClick={() => handleOpen(entry)}>
								<span>→ </span>
								{name}
								{active && <small className="badge badge-pill badge-danger ml-2">LIVE</small>}
							</Link>
						);
					}
				})}
			</div>
		</Overlay>
	);
}
