import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Angels from '../../images/event/Angels.jpg';
import Animal_Health from '../../images/event/Animal-Health.jpg';
import Metabolic from '../../images/event/Metabolic.jpg';
import Plenary from '../../images/event/Plenary.jpg';
import Pradaxa from '../../images/event/Pradaxa.jpg';
import Respiratory from '../../images/event/Respiratory.jpg';
import SpecialtyBusinessUnit from '../../images/event/Specialty-Business-Unit.jpg';
import Twynsta from '../../images/event/Twynsta.jpg';
import routes from '../../routes';
import { COLORS } from '../../styles/theme';
import LoadIcon2 from '../../components/LoadIcon2';

const MobileMenu = (props) => {
	const [ loading, setLoading ] = useState(true);
	const [ selectedAgenda, setSelectedAgenda ] = useState({ id: 1, name: 'Plenary', image: Plenary });
	const agendaList = [
		{ id: 1, name: 'Plenary Session', image: Plenary },
		{ id: 2, name: 'Angels', image: Angels },
		{ id: 3, name: 'Animal Health', image: Animal_Health },
		{ id: 4, name: 'Metabolic', image: Metabolic },
		{ id: 5, name: 'Pradaxa', image: Pradaxa },
		{ id: 6, name: 'Respiratory', image: Respiratory },
		{ id: 7, name: 'Specialty Business Unit', image: SpecialtyBusinessUnit },
		{ id: 8, name: 'Twynsta', image: Twynsta }
	];

	return (
		<div>
			<Page>
				<div className="outer-wrapper">
					<div className="container">
						<div className="title-container">
							<p className="title">Agenda</p>
							<Link className="back-button" to={routes.lobby}>
								Back
							</Link>
						</div>
						<div className="tab-container">
							{agendaList.map((agenda) => (
								<p
									key={`agenda-${agenda.id}`}
									className={`${selectedAgenda.id === agenda.id && 'active'}`}
									onClick={() => {
										setSelectedAgenda(agenda);
										setLoading(true);
									}}
								>
									{agenda.name}
								</p>
							))}
						</div>
					</div>
					{loading && <LoadIcon2 style={{ top: '20%' }} />}
					<img
						src={selectedAgenda.image}
						onLoad={() => {
							setLoading(false);
						}}
						className="item-image"
						alt="event-agenda"
					/>
				</div>
			</Page>
		</div>
	);
};

const Page = styled.div`
	background: white;
	height: calc(100% - 60px);
	width: 100vw;
	overflow-y: scroll;
	border-radius: 0px 0px 25px 25px;
	position: absolute;

	.outer-wrapper {
		width: 100%;
	}

	.container {
		width: 90%;
		margin: auto;
	}

	.title-container {
		margin: 20px 0px 10px 0px;
		height: 50px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.title {
		width: 70%;
		font-size: 22px;
		font-weight: bold;
		font-style: italic;
		color: black;
		margin: 0;
	}

	.back-button {
		font-size: 12px;
		color: #312384;
		font-weight: bold;
	}

	.profile-container {
		width: 100%;
		height: 100px;
		margin: 10px 0px;
		display: flex;
		align-items: center;
	}

	.profile-image {
		width: 70px;
		height: 70px;
		border-radius: 50%;
		margin-right: 20px;
		/*background: #02c6c0;*/
		background: lightgrey;
	}

	.profile-info-container {
		width: calc(100% - 90px);
		height: 55px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.profile-info-name {
		font-size: 20px;
		font-weight: bold;
		margin-bottom: 0px;
	}

	.tab-container {
		width: 100%;
		margin: auto;
		display: flex;
		flex-wrap: wrap;
		font-size: 12px;
		font-weight: bold;
		p {
			border: 2px solid ${COLORS.primary};
			color: ${COLORS.primary};
			border-radius: 10px;
			padding: 2px 8px;
			margin: 3px;
			@media screen and (min-width: 768px) {
				font-size: 20px;
				padding: 5px 10px;
				margin: 5px;
			}
		}

		.active {
			background: #002060;
			color: white;
		}
	}

	.about-container {
		font-size: 12px;
		text-align: center;
		margin-top: 20px;
	}

	.speaker-day {
		font-size: 14px;
		font-weight: bold;
		font-style: italic;
		margin: 20px 0px;
	}

	.speaker-list {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
	}

	.speaker-item-wrapper {
		width: 33.33%;
		display: flex;
		justify-content: center;
	}

	.speaker-item-container {
		width: 70px;
		height: 95px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;
	}

	.speaker-item-image {
		width: 70px;
		height: 70px;
		border-radius: 10px;
		border: solid 2px #662283;
		background: #ede1fe;
	}

	.speaker-item-name {
		font-size: 12px;
		text-align: center;
		margin-bottom: 0;
	}

	.item-image {
		margin-top: 10px;
		width: 100%;
	}

	.avatar {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 50%;
	}

	@media screen and (min-width: 540px) {
		.outer-wrapper {
			/* width: 540px; */
			margin: auto;
		}
	}
`;

export default MobileMenu;
